import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { columnDefs, defaultColDef } from "./columns";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./App.css";
// import {getItem} from './generalMethods';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
// import MakeFieldComponent from "./MakeFieldComponent";

import axios from "axios";
// import { buses } from "../../server/models";

class SoldBuses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modelVisibility: true,
      columnDefs: { columnDefs },
      defaultColDef: { defaultColDef },

      rowData: [],
      frameworkComponents: {
        // makeFieldComponent: MakeFieldComponent,
      },
    };
  }

  componentDidMount() {
    axios
      .get("https://mern-heroku-netlify.herokuapp.com/api/buses/inventoryType")
      .then((response) => {
        this.setState({ rowData: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  };

  onCellValueChanged = ({ data }) => {
    /*       console.log(`update db: ${JSON.stringify(data)}`);
      console.log(data._id); */

    axios
      .put(
        `https://mern-heroku-netlify.herokuapp.com/api/buses/${data._id}`,
        data
      )
      .then((response) => data)
      .catch((error) => {
        console.log(error);
      });
  };

  //Export to CSV function
  onBtnExport = (params) => {
    this.gridApi.exportDataAsCsv(params);
  };

  /* Autosize columns */

  autoSizeAllColumns = () => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeAllColumns(allColumnIds);
  };

  sizeToFit = () => {
    this.gridColumnApi.sizeColumnsToFit();
  };

  // Quick filter
  onFilterTextBoxChanged = () => {
    this.gridApi.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  };

  // Delete selected row
  getSelectedRowData = (params) => {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedRowData = this.gridApi.getSelectedRows();
    let selectedData = selectedNodes.map((node) => node.data._id);

    if (selectedData.toString() === "") {
      alert(`No row selected. Please select a row.`);
    } else {
      this.gridApi.applyTransaction({ remove: selectedRowData });

      // growlBL.current.show({ life: 8000, severity:'error', summary: 'Delete Message', detail:`Unit has been deleted`});

      axios.delete(
        `https://mern-heroku-netlify.herokuapp.com/api/buses/${selectedData.toString()}`
      );

      this.gridApi.redrawRows();
    }
  };

  render() {
    return (
      <div className="my-app">
        <div
          id="myGrid"
          style={{ height: "55vh", width: "100%", marginTop: "8em" }}
          className="ag-theme-balham"
        >
          {/* GLOBAL SEARCH AND EXPORT FIELDS */}
          <div
            className="p-grid p-align-center p-justify-between"
            style={{ margin: "0.3em 0" }}
          >
            <div className="p-col-1">
              <InputText
                type="text"
                id="filter-text-box"
                placeholder="Global Search..."
                onChange={() => this.onFilterTextBoxChanged()}
              ></InputText>
            </div>
            <div className="p-col-9">
              <h1 style={{ textAlign: "center", fontSize: "4em" }}>
                Sold Inventory
              </h1>
            </div>
            <div className="p-col-auto ">
              <Button
                type="button"
                icon="pi pi-external-link"
                label="Export to PDF"
                onClick={() => this.onBtPrint()}
              ></Button>
              <Button
                type="button"
                icon="pi pi-external-link"
                label="Export to CSV"
                onClick={() => this.onBtnExport()}
              ></Button>
            </div>
          </div>

          <AgGridReact
            autoSizeAllColumns={this.autoSizeAllColumns}
            onGridReady={this.onGridReady}
            rowSelection="single"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={this.state.rowData}
            getRowNodeId={(data) => data._id}
            animateRows={false}
            suppressColumnVirtualisation={false}
            frameworkComponents={this.state.frameworkComponents}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={5}
            onCellValueChanged={this.onCellValueChanged}
          ></AgGridReact>

          {/* DELETE BUTTON FOR GRID DATA */}
          <div
            div
            className="p-grid p-justify-between p-col-12 p-md-6 p-lg-12"
            style={{ margin: "0.8em 0" }}
          >
            {/* Edit button for selected row data */}
            {/* <Button  className="p-button-warning" type='button' label="Edit" icon="pi pi-trash" onClick={() =>editPage()} ></Button> */}
            {/* Delted button for selected row data */}
            <Button
              className="p-button-danger"
              type="button"
              label="Delete"
              icon="pi pi-trash"
              onClick={() => this.getSelectedRowData()}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SoldBuses);

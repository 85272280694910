import React, { Component } from "react";
import { Panel } from "primereact/components/panel/Panel";
import axios from "axios";
import LogoutButton from "./LogoutButton";

export class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      buses: [],
      InStockDashboardCount: 0,
      OnOrderDashboardCount: 0,
      AgedDashboardCount: 0,
      city: null,
    };
  }

  componentDidMount() {
    // REQUEST DATA FROM DATABASE IN MONGODB
    axios
      .get("https://mern-heroku-netlify.herokuapp.com/api/buses/")
      .then((response) => {
        this.setState({ buses: response.data });

        if (this.state.buses) {
          for (let customer of this.state.buses) {
            if (customer.inventoryType === "Stock") {
              this.setState({
                InStockDashboardCount: this.state.InStockDashboardCount + 1,
              });
            }

            if (customer.inventoryType === "On Order") {
              this.setState({
                OnOrderDashboardCount: this.state.OnOrderDashboardCount + 1,
              });
            }
            if (customer.inventoryType === "Clearance") {
              this.setState({
                ClearanceDashboardCount: this.state.ClearanceDashboardCount + 1,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="p-grid dashboard">
        <div className="p-col p-justify-end">
          <LogoutButton />
        </div>

        <div className="p-col-12">
          <Panel header="HBS Inventory" className="circle-panel">
            <div className="p-grid">
              <div className="p-col-12 p-lg-4 p-md-6">
                <div className="status-title" style={{ color: "#6ebc3b" }}>
                  In Stock
                </div>
                <div className="circle1" style={{ paddingLeft: "50px" }}>
                  {/* <i className="pi pi-eye"></i> */}
                  <span>{this.state.InStockDashboardCount}</span>
                </div>
              </div>
              {/*                         <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="status-title" style={{color:'#f6a821'}}>Commercial</div>
                            <div className="circle2">
                                <i className="pi pi-eye"></i>
                                <span>25</span>
                            </div>
                        </div> */}
              <div className="p-col-12 p-lg-4 p-md-6">
                <div className="status-title" style={{ color: "#039ade" }}>
                  On Order
                </div>
                <div className="circle3" style={{ paddingLeft: "50px" }}>
                  {/* <i className="pi pi-eye"></i> */}
                  <span>{this.state.OnOrderDashboardCount}</span>
                </div>
              </div>
              <div className="p-col-12 p-lg-4 p-md-6">
                <div className="status-title" style={{ color: "#d66351" }}>
                  Aged
                </div>
                <div className="circle4" style={{ paddingLeft: "50px" }}>
                  {/* <i className="pi pi-eye"></i> */}
                  <span>{this.state.AgedDashboardCount}</span>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

// import {
//   Box,
//   Button,
//   IconButton,
//   Typography,
//   colors,
//   useTheme,
// } from "@mui/material";
// import { tokens } from "../../theme";
// import Header from "../Header";

import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Topbar } from "../global/Topbar";
import TopDashboard from "./topDash";

function MaxDashboard() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app-max">
          <main className="content">
            <Topbar />
            <TopDashboard />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
export default MaxDashboard;

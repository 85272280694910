import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";

const makeReadyTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "Pending", label: "Pending" },
  { value: "In Process", label: "In Process" },
  { value: "Show Line", label: "Show Line" },
];

const pdiTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "350", label: "350" },
  { value: "500", label: "500" },
  { value: "750", label: "750" },
];

export default function CostOfGoodsForm(props) {
  const [value1, setValue1] = useState("");
  // const [value, setValue] = React.useState(100);

  const {
    formField: {
      newChassisCost,
      newBodyCost,
      usedBusCost,
      freightCost,
      pdi,
      interestPac,
      adminPac,
      goodWillPac,
      makeReadyProjection,
      makeReadyCost,
      paintBodyProjection,
      paintBodyCost,
      // makeReadyStatus,
      salesPrice,
      rebates,
      notes,
    },
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Cost Of Goods
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={newChassisCost.name}
            label={newChassisCost.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={newBodyCost.name}
            label={newBodyCost.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={usedBusCost.name}
            label={usedBusCost.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={freightCost.name}
            label={freightCost.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            name={pdi.name}
            label={pdi.label}
            data={pdiTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={interestPac.name}
            label={interestPac.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={adminPac.name}
            label={adminPac.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={goodWillPac.name}
            label={goodWillPac.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={makeReadyProjection.name}
            label={makeReadyProjection.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={makeReadyCost.name}
            label={makeReadyCost.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={paintBodyProjection.name}
            label={paintBodyProjection.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={paintBodyCost.name}
            label={paintBodyCost.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={rebates.name}
            label={rebates.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputField
            type="number"
            name={salesPrice.name}
            label={salesPrice.label}
            fullWidth
          />
        </Grid>

        {/* <Grid item xs={12} md={3}>
          <SelectField
            type="text"
            name={makeReadyStatus.name}
            label={makeReadyStatus.label}
            data={makeReadyTypes}
            fullWidth
          />
        </Grid> */}

        {/*         <Grid item xs={12} md={3}>
        <h5>Notes:</h5>
        <TextArea rows={2} cols={30} 
            value={value1}
            label={notes.label}
            onChange={(e) => setValue1(e.target.value)}
            autoResize
          />
        </Grid> */}

        {/*         <Grid item xs={12} md={3}>
        <InputField type="number"

            name={totalBusCost.name}
            label={totalBusCost.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
        <InputField type="number"

            name={totalProdCogs.name}
            label={totalProdCogs.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
        <InputField type="number"

            name={totalCost.name}
            label={totalCost.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
        <InputField type="number"

            name={grossProfit.name}
            label={grossProfit.label}
            fullWidth
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}

import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import axios from "axios";

export class PreOwnedMFSAB extends Component {
  constructor() {
    super();

    this.columns = [
      { field: "stock", header: "Stock" },
      // {field: "inventoryType"  , header: "Inventory Type"},
      // {field: "busType"  , header: "Bus Type" },
      { field: "vehicleCondition", header: "Vehicle Condition" },
      { field: "busYear", header: "Bus Year" },
      { field: "busMake", header: "Bus Make" },
      { field: "busModel", header: "Bus Model" },

      { field: "amb", header: "Amb" },

      /*             {field: "wc"  , header: "WC" },
            {field: "rearStorage"  , header: "ReaR Storage" },
            {field: "overHeadStorage"  , header: "Over Head Storage" }, */
      { field: "bodyColor", header: "Body Color" },
      { field: "chassisYear", header: "Chassis Year" },
      { field: "chassisMake", header: "Chassis Make" },
      { field: "chassisModel", header: "Chassis Model" },
      /*             {field: "axle"  , header: "Axle" },
            {field: "engine"  , header: "Engine" },
            {field: "fuelType"  , header: "Fuel Type" },
            {field: "gvwr"  , header: "GVWR" },
            {field: "mileage"  , header: "Mileage" },
            {field: "seatMake"  , header: "Seat Make" },
            {field: "seatModel"  , header: "Seat Model" },
            {field: "seatSize"  , header: "Seat Size" },
            {field: "seatUph"  , header: "Seat Uph" },
            {field: "seatUphColor"  , header: "Seat Uph Color" },
            {field: "seatBelt"  , header: "Seat Belt" },
            {field: "vin"  , header: "Vin" }, */
      /*             {field: "bodyNumber"  , header: "Body Number" },
            {field: "arrivalStatus"  , header: "Arrival Status" },
            {field: "currentLocation"  , header: "Current Location" },
            {field: "previousOwner"  , header: "Previous Owner" },
            {field: "pickUpLocationCity"  , header: "Pick-up Location City" },
            {field: "pickUpLocationState"  , header: "Pick-up Location State" },
            {field: "chassisOrderDate"  , header: "Chassis Order Date" },
            {field: "chassisTargetedCompletion"  , header: "Chassis Requested Completion" },
            {field: "chassisCompletion"  , header: "Chassis Completion" },
            {field: "chassisInvoice"  , header: "Chassis Invoice" },
            {field: "chassisAtManufacturer"  , header: "Chassis at Collins" },
            {field: "chassisAging"  , header: "Chassis Aging" },
            {field: "bodyOrderDate"  , header: "Body Order Date" },
            {field: "bodyTargetedCompletion"  , header: "Body Requested Completion" },
            {field: "bodyCompletion"  , header: "Body Completion" },
            {field: "bodyInvoice"  , header: "Body Invoice" },
            {field: "daysOwned"  , header: "Days Owned" },
            {field: "floorDate"  , header: "Floor Date" },
            {field: "daysFloored"  , header: "Days Floored" },
            {field: "newChassisCost"  , header: "New Chassis Cost" },
            {field: "newBodyCost"  , header: "New Body Cost" },
            {field: "usedBusCost"  , header: "Used bus Cost" },
            {field: "totalBusCost"  , header: "Total Bus Cost" },
            {field: "freightCost"  , header: "Freight Cost" },
            {field: "pdi"  , header: "PDI" },
            {field: "interestPac"  , header: "Interest Pac" },
            {field: "adminPac"  , header: "Admin Pac" },
            {field: "goodWillPac"  , header: "Goodwill Pac" },
            {field: "makeReadyStatus"  , header: "Make Ready Status" },
            {field: "makeReadyProjection"  , header: "Make Ready Projection" },
            {field: "makeReadyCost"  , header: "Make Ready Cost" },
            {field: "paintBodyProjection"  , header: "Paint Body Projection" },
            {field: "paintBodyCost"  , header: "Paint Body Cost" },
            {field: "totalProdCogs"  , header: "ToTal Prod Cogs" },
            {field: "totalCost"  , header: "Total Cost" }, */
      // {field: "salesPrice"  , header: "Sales Price" }
      /*             {field: "grossProfit"  , header: "Gross Profit" },
            {field: "rebates"  , header: "Rebates" },
            {field: "revisedGp"  , header: "Revised Gp" },
            {field: "grossMargin"  , header: "Gross Margin" } */
    ];
    this.state = {
      buses: [],
      expandedRowGroups: [],
      selectedColumns: this.columns,
      globalFilter: null,
      loading: false,
      filters: {
        inventoryType: {
          value: "Stock",
        },
      },
    };

    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.onColumnToggle = this.onColumnToggle.bind(this);
    this.headerTemplate = this.headerTemplate.bind(this);
    this.footerTemplate = this.footerTemplate.bind(this);
    this.priceBodyTemplate = this.priceBodyTemplate.bind(this);

    this.exportCSV = this.exportCSV.bind(this);
  }

  // REQUEST DATA FROM DATABASE IN MONGODB
  componentDidMount() {
    axios
      .get("https://mern-heroku-netlify.herokuapp.com/api/buses/preownedmfsab")
      .then((response) => {
        this.setState({ buses: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Export data to csv file

  exportCSV() {
    this.dt.exportCSV();
  }

  /* FIELD CALCULATIONS FOR THE COSTING DATA FIELDS - START */
  formatCurrency(value) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  priceBodyTemplate(rowData) {
    return this.formatCurrency(rowData.salesPrice);
  }

  /* FIELD CALCULATIONS FOR THE COSTING DATA FIELDS - END */

  /* CHANGE EVENTS - START */

  onColumnToggle(event) {
    let selectedColumns = event.value;
    let orderedSelectedColumns = this.columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    this.setState({ selectedColumns: orderedSelectedColumns });
  }

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  onBrandChange(event) {
    this.dt.filter(event.value, "brand", "equals");
    this.setState({ datatableBrand: event.value });
  }

  onColorChange(event) {
    this.dt.filter(event.value, "color", "in");
    this.setState({ datatableColors: event.value });
  }

  /* CHANGE EVENTS - END */

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-search"
          className="p-button-success"
          style={{ marginRight: ".5em" }}
        />
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
        />
      </div>
    );
  }

  headerTemplate(data) {
    return (
      <React.Fragment>
        <span
          className="image-text"
          style={{ fontWeight: "bold", fontSize: "1.5em" }}
        >
          {data.busSize}
        </span>
      </React.Fragment>
    );
  }

  footerTemplate(data) {
    return (
      <React.Fragment>
        <td colSpan="5" style={{ textAlign: "right" }}>
          Total Buses
        </td>
        <td>{this.calculateCustomerTotal(data.busSize)}</td>
      </React.Fragment>
    );
  }

  calculateCustomerTotal(name) {
    let total = 0;

    if (this.state.buses) {
      for (let customer of this.state.buses) {
        if (customer.busSize === name && customer.inventoryType === "Stock") {
          total++;
        }
      }
    }

    return total;
  }

  render() {
    /*         // Pagination
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
        const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
 */

    let header = (
      <div className="p-grid" style={{ textAlign: "left" }}>
        <div
          className="p-col-fixed"
          style={{ width: "350px", padding: "0.3em" }}
        >
          <h1 style={{ fontWeight: "bolder" }}>Pre-Owned MFSAB Inventory</h1>
        </div>

        <div className="p-col-fixed" style={{ width: "400px" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
            size="50"
          />
        </div>
        <div className="p-col-fixed" style={{ width: "97px" }}>
          Column Filter
        </div>
        <div className="p-col-fixed" style={{ width: "320px" }}>
          <MultiSelect
            value={this.state.selectedColumns}
            options={this.columns}
            optionLabel="header"
            onChange={this.onColumnToggle}
            style={{ width: "20em" }}
          />
        </div>
        <div className="p-col">
          <Button
            type="button"
            icon="pi pi-external-link"
            label="Export"
            onClick={this.exportCSV}
          ></Button>
        </div>
      </div>
    );

    const columnComponents = this.state.selectedColumns.map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={true}
          filter={false}
          columnResizeMode="fit"
          filterPlaceholder="Contains"
        />
      );
    });

    return (
      <div>
        <div className="card" style={{ width: "135em" }}>
          <DataTable
            className="p-datatable-sm p-datatable-striped"
            value={this.state.buses}
            header={header}
            style={{ width: "140em" }}
            globalFilter={this.state.globalFilter}
            autoLayout={true}
            rowGroupMode="subheader"
            groupField="busSize"
            expandableRowGroups
            expandedRows={this.state.expandedRows}
            onRowToggle={(e) => this.setState({ expandedRows: e.data })}
            sortMode="single"
            sortField="busSize"
            sortOrder={-1}
            removableSort
            rowGroupHeaderTemplate={this.headerTemplate}
            rowGroupFooterTemplate={this.footerTemplate}
            scrollable
            scrollHeight="90em"
            /*                         paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
                        paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} */
            resizableColumns
            columnResizeMode="expand"
            ref={(el) => {
              this.dt = el;
            }}
            filters={this.state.filters}
            onFilter={(e) => this.setState({ filters: e.filters })}
          >
            <Column
              field="busSize"
              header="Bus Size"
              sortable={true}
              filter={false}
              filterPlaceholder="Contains"
              style={{ fontSize: "0.85em", visibility: "hidden" }}
              columnResizeMode="expand"
            />
            {columnComponents}
            {/*                         <Column field="stock" header= "Stock" style={{width:'10%'}} />
                        <Column field="inventoryType" header= "Inventory Type" style={{width:'10%'}} /> */}

            <Column
              field="salesPrice"
              header="Sales Price"
              sortField={true}
              filter={false}
              filterPlaceholder="Contains"
              body={this.priceBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}

import React from "react";
import { Typography, Grid } from "@material-ui/core";

function PaymentDetails(props) {
  const { formValues } = props;

  const {
    inventoryType,
    busType,
    vehicleCondition,
    stock,
    busYear,
    busMake,
    busModel,
    amb,
    wc,
    rearStorage,
    overHeadStorage,
    bodyColor,
    chassisYear,
    chassisMake,
    chassisModel,
    axle,
    engine,
    fuelType,
    gvwr,
    mileage,
    seatMake,
    seatModel,
    seatSize,
    seatUph,
    seatUphColor,
    seatBelt,
    vin,
    bodyNumber,
    arrivalStatus,
    currentLocation,
    previousOwner,
    pickUpLocationCity,
    pickUpLocationState,
    chassisOrderDate,
    chassisTargetedCompletion,
    chassisCompletion,
    chassisInvoice,
    chassisAtManufacturer,
    bodyOrderDate,
    bodyTargetedCompletion,
    bodyCompletion,
    bodyInvoice,
    floorDate,
    newChassisCost,
    newBodyCost,
    freightCost,
    pdi,
    interestPac,
    adminPac,
    goodWillPac,
    makeReadyStatus,
    makeReadyProjection,
    makeReadyCost,
    paintBodyProjection,
    paintBodyCost,
    salesPrice,
    notes,

    checkInDate,
    showlineDate,
    worksheetPresentationComplete,
    makeReadyStandard,
    showlinePhotos,
    stockFolderBuilt,
    stockFolderComplete,

  } = formValues;
  return (
    <Grid item xs={12} sm={6}>
      <div>
        <h2>Bus Body Description</h2>
        <hr></hr>
        <Typography
          gutterBottom
        >{`Invetory Type: ${inventoryType}`}</Typography>
        <Typography gutterBottom>{`Bus Type: ${busType}`}</Typography>
        <Typography
          gutterBottom
        >{`Vehicle Condition: ${vehicleCondition}`}</Typography>
        <Typography gutterBottom>{`Stock#: ${stock}`}</Typography>
        <Typography gutterBottom>{`Bus Year: ${busYear}`}</Typography>
        <Typography gutterBottom>{`Bus Make: ${busMake}`}</Typography>
        <Typography gutterBottom>{`Bus Model: ${busModel}`}</Typography>

        <Typography gutterBottom>{`Amb: ${amb}`}</Typography>

        <Typography gutterBottom>{`WC: ${wc}`}</Typography>
        <Typography gutterBottom>{`Rear Storage: ${rearStorage}`}</Typography>
        <Typography
          gutterBottom
        >{`Over Head Storage: ${overHeadStorage}`}</Typography>
        <Typography gutterBottom>{`Body Color: ${bodyColor}`}</Typography>
        <Typography gutterBottom>{`Body Number: ${bodyNumber}`}</Typography>
      </div>

      <div>
        <h2>Bus Chassis Description</h2>
        <hr></hr>
        <Typography gutterBottom>{`Chassis Year: ${chassisYear}`}</Typography>
        <Typography gutterBottom>{`Chassis Make: ${chassisMake}`}</Typography>
        <Typography gutterBottom>{`Chassis Model: ${chassisModel}`}</Typography>
        <Typography gutterBottom>{`Axle: ${axle}`}</Typography>
        <Typography gutterBottom>{`Engine: ${engine}`}</Typography>
        <Typography gutterBottom>{`Fuel Type: ${fuelType}`}</Typography>
        <Typography gutterBottom>{`GVWR: ${gvwr}`}</Typography>
        <Typography gutterBottom>{`Mileage: ${mileage}`}</Typography>
        <Typography gutterBottom>{`Seat Make: ${seatMake}`}</Typography>
        <Typography gutterBottom>{`Seat Model: ${seatModel}`}</Typography>
        <Typography gutterBottom>{`Seat Size: ${seatSize}`}</Typography>
        <Typography gutterBottom>{`Seat Uph: ${seatUph}`}</Typography>
        <Typography
          gutterBottom
        >{`Seat Uph Color: ${seatUphColor}`}</Typography>
        <Typography gutterBottom>{`Seat Belt: ${seatBelt}`}</Typography>
        <Typography gutterBottom>{`VIN#: ${vin}`}</Typography>
      </div>

      <div>
        <h2>Additional Information</h2>
        <hr></hr>
        <Typography
          gutterBottom
        >{`Arrival Status: ${arrivalStatus}`}</Typography>
        <Typography
          gutterBottom
        >{`Current Location: ${currentLocation}`}</Typography>
        <Typography
          gutterBottom
        >{`Previous Owner: ${previousOwner}`}</Typography>
        <Typography
          gutterBottom
        >{`Pick-up Location City: ${pickUpLocationCity}`}</Typography>
        <Typography
          gutterBottom
        >{`Pick-up Location State: ${pickUpLocationState}`}</Typography>
        <Typography
          gutterBottom
        >{`Chassis Order Date: ${chassisOrderDate}`}</Typography>
        <Typography
          gutterBottom

        >{`Chassis Targeted Completion: ${chassisTargetedCompletion}`}</Typography>

        <Typography
          gutterBottom
        >{`Chassis Completion: ${chassisCompletion}`}</Typography>
        <Typography
          gutterBottom
        >{`Chassis Invoice: ${chassisInvoice}`}</Typography>
        <Typography
          gutterBottom

        >{`Chassis At Manufacturer: ${chassisAtManufacturer}`}</Typography>

        <Typography
          gutterBottom
        >{`Body Order Date: ${bodyOrderDate}`}</Typography>
        <Typography
          gutterBottom

        >{`Body Targeted Completion: ${bodyTargetedCompletion}`}</Typography>

        <Typography
          gutterBottom
        >{`Body Completion: ${bodyCompletion}`}</Typography>
        <Typography gutterBottom>{`Body Invoice: ${bodyInvoice}`}</Typography>
        <Typography gutterBottom>{`Floor Date: ${floorDate}`}</Typography>

        <Typography gutterBottom>{`Check-in Date: ${checkInDate}`}</Typography>
        <Typography
          gutterBottom
        >{`Show Line Date: ${showlineDate}`}</Typography>
        <Typography
          gutterBottom
        >{`Worksheet Presentation Complete: ${worksheetPresentationComplete}`}</Typography>
        <Typography
          gutterBottom
        >{`Make Ready Standard: ${makeReadyStandard}`}</Typography>
        <Typography
          gutterBottom
        >{`Show Line Photos: ${showlinePhotos}`}</Typography>
        <Typography
          gutterBottom
        >{`Stock Folder Built: ${stockFolderBuilt}`}</Typography>
        <Typography
          gutterBottom
        >{`Stock Folder Complete: ${stockFolderComplete}`}</Typography>

      </div>

      <div>
        <h2>Cost of Goods</h2>
        <hr></hr>

        <Typography
          gutterBottom
        >{`New Chassis Cost: ${newChassisCost}`}</Typography>
        <Typography gutterBottom>{`New Body Cost: ${newBodyCost}`}</Typography>
        <Typography gutterBottom>{`Freight Cost: ${freightCost}`}</Typography>
        <Typography gutterBottom>{`PDI: ${pdi}`}</Typography>
        <Typography gutterBottom>{`Interest Pac: ${interestPac}`}</Typography>
        <Typography gutterBottom>{`Admin Pac: ${adminPac}`}</Typography>
        <Typography gutterBottom>{`Good Will Pac: ${goodWillPac}`}</Typography>
        <Typography
          gutterBottom
        >{`Make Ready Status: ${makeReadyStatus}`}</Typography>
        <Typography
          gutterBottom
        >{`Make Ready Projection: ${makeReadyProjection}`}</Typography>
        <Typography
          gutterBottom
        >{`Make Ready Cost: ${makeReadyCost}`}</Typography>
        <Typography
          gutterBottom
        >{`Paint Body Projection: ${paintBodyProjection}`}</Typography>
        <Typography
          gutterBottom
        >{`Paint Body Cost: ${paintBodyCost}`}</Typography>
        <Typography gutterBottom>{`Sales Price: ${salesPrice}`}</Typography>
        <Typography gutterBottom>{`Notes: ${notes}`}</Typography>
      </div>
    </Grid>
  );
}

export default PaymentDetails;

"use client";

import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "9aa3d12257e7c6c675dbf5596d3d8473Tz04NDE5NSxFPTE3Mzk0NjkzNDAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

export default function MuiXLicense() {
  return null;
}

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import MainLogin from "./MainLogin";

// This function is from my auth0-react-qs app
function Main() {
  const { loading } = useAuth0();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <header>
        <MainLogin />
      </header>
    </div>
  );
}

export default Main;

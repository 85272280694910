import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";

const invTypes = [
  {
    value: undefined,
    label: "None",
  },
  {
    value: "Stock",
    label: "Stock",
  },
  {
    value: "Stock On Order",
    label: "Stock On Order",
  },
  {
    value: "Consignment",
    label: "Consignment",
  },
  {
    value: "Aged",
    label: "Aged",
  },
  {
    value: "Sale Pending",
    label: "Sale Pending",
  },
  {
    value: "Sold",
    label: "Sold",
  },
  {
    value: "Sold On Order",
    label: "Sold On Order",
  },
];

const busTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "MFSAB", label: "MFSAB" },
  { value: "Commercial", label: "Commercial" },
  { value: "Van", label: "Van" },
  { value: "MPV", label: "MPV" },
  { value: "Specialty", label: "Specialty" },
];

const sizeTypes = [
  {
    value: undefined,
    label: "None",
  },
  {
    value: "Small Buses (up to 15 pass, non-CDL)",
    label: "Small Buses (up to 15 pass, non-CDL)",
  },
  {
    value: "Mid-Size Buses (16 to 33 pass, CDL)",
    label: "Mid-Size Buses (16 to 33 pass, CDL)",
  },
  {
    value: "Large Buses (34 to 78 pass, CDL)",
    label: "Large Buses (34 to 78 pass, CDL)",
  },
];

const yearTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "1995", label: "1995" },
  { value: "1996", label: "1996" },
  { value: "1997", label: "1997" },
  { value: "1998", label: "1998" },
  { value: "1999", label: "1999" },
  { value: "2000", label: "2000" },
  { value: "2001", label: "2001" },
  { value: "2002", label: "2002" },
  { value: "2003", label: "2003" },
  { value: "2004", label: "2004" },
  { value: "2005", label: "2005" },
  { value: "2006", label: "2006" },
  { value: "2007", label: "2007" },
  { value: "2008", label: "2008" },
  { value: "2009", label: "2009" },
  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
  { value: "2026", label: "2026" },
  { value: "2027", label: "2027" },
  { value: "2028", label: "2028" },
  { value: "2029", label: "2029" },
  { value: "2030", label: "2030" },
];

const makeTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Blue Bird", label: "Blue Bird" },
  { value: "Caleche", label: "Caleche" },
  { value: "Collins", label: "Collins" },
  { value: "Corbeil", label: "Corbeil" },
  { value: "Diamond", label: "Diamond" },
  { value: "Eldorado", label: "Eldorado" },
  { value: "Elkhart Coach Designs", label: "Elkhart Coach Designs" },
  { value: "Endera", label: "Endera" },
  { value: "Federal", label: "Federal" },
  { value: "Ford", label: "Ford" },
  { value: "FR Conversions", label: "FR Conversions" },
  { value: "General Coach", label: "General Coach" },
  { value: "Girardin", label: "Girardin" },
  { value: "Glaval", label: "Glaval" },
  { value: "Goshen", label: "Goshen" },
  { value: "International", label: "International" },
  { value: "LA West", label: "LA West" },
  { value: "Mercedes", label: "Mercedes" },
  { value: "Mid Bus", label: "Mid Bus" },
  { value: "MT", label: "MT" },
  { value: "Pegasus", label: "Pegasus" },
  { value: "Revability", label: "Revability" },
  { value: "StarCraft", label: "StarCraft" },
  { value: "StarCraft", label: "StarCraft" },
  { value: "StarTrans", label: "StarTrans" },
  { value: "Thomas", label: "Thomas" },
  { value: "Turtle Top", label: "Turtle Top" },
  { value: "US BUS", label: "US BUS" },
  { value: "VanCon", label: "VanCon" },
  { value: "Winnebago", label: "Winnebago" },
];

const modelTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "TL400", label: "TL400" },
  { value: "TH400", label: "TH400" },
  { value: "TH500", label: "TH500" },
  { value: "DH400", label: "DH400" },
  { value: "DH500", label: "DH500" },
  { value: "DH416", label: "DH416" },
  { value: "DE516", label: "DE516" },
  { value: "DH516", label: "DH516" },
  { value: "SH400", label: "SH400" },
  { value: "SL400", label: "SL400" },
  { value: "Bantam Lo-Top", label: "Bantam Lo-Top" },
  { value: "Bantam Hi-Top", label: "Bantam Hi-Top" },
  { value: "Super Bantam", label: "Super Bantam" },
  { value: "MBII", label: "MBII" },
  { value: "Micro Bird", label: "Micro Bird" },
  { value: "Minotour", label: "Minotour" },
  { value: "MyBus", label: "MyBus" },
  { value: "Prodigy", label: "Prodigy" },
  { value: "Titan II", label: "Titan II" },
  { value: "Advance", label: "Advance" },
  { value: "Advantage", label: "Advantage" },
  { value: "Advantage 220", label: "Advantage 220" },
  { value: "Advantage 270", label: "Advantage 270" },
  { value: "Aero Elite", label: "Aero Elite" },
  { value: "Aerotech 240", label: "Aerotech 240" },
  { value: "Allstar", label: "Allstar" },
  { value: "AllStar XL", label: "AllStar XL" },
  { value: "Dodge Grand Caravan", label: "Dodge Grand Caravan" },
  { value: "EZ Trans", label: "EZ Trans" },
  { value: "Impulse 220", label: "Impulse 220" },
  { value: "Legacy", label: "Legacy" },
  { value: "Lift Equip. Van", label: "Lift Equip. Van" },
  { value: "Low-Floor Commercial", label: "Low-Floor Commercial" },
  { value: "Metro Link", label: "Metro Link" },
  { value: "Odyssey", label: "Odyssey" },
  { value: "Spirit", label: "Spirit" },
  { value: "Sprinter", label: "Sprinter" },
  { value: "Transit Connect XLT", label: "Transit Connect XLT" },
  { value: "Transit XLT", label: "Transit XLT" },
  { value: "VIP 2000", label: "VIP 2000" },
  { value: "VIP 2200", label: "VIP 2200" },
  { value: "VIP 2800", label: "VIP 2800" },
  { value: "Nugget", label: "Nugget" },
];

const rearStorageTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Dedicated", label: "Dedicated" },
  { value: "Non-Dedicated", label: "Non-Dedicated" },
];

const overHeadStorageTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Modular", label: "Modular" },
  { value: "Open", label: "Open" },
];

const bodyColorTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "White", label: "White" },
  { value: "Black", label: "Black" },
  { value: "Yellow", label: "Yellow" },
  { value: "Other", label: "Other" },
];

const axleTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "SRW", label: "SRW" },
  { value: "DRW", label: "DRW" },
];

const chassisMakeTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Chevrolet", label: "Chevrolet" },
  { value: "Dodge", label: "Dodge" },
  { value: "Ford", label: "Ford" },
  { value: "Freightliner", label: "Freightliner" },
  { value: "GMC", label: "GMC" },
  { value: "International", label: "International" },
  { value: "Ram", label: "Ram" },
  { value: "Mercedes", label: "Mercedes" },
];

const chassisModelTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "T250", label: "T250" },
  { value: "T350", label: "T350" },
  { value: "E350", label: "E350" },
  { value: "E450", label: "E450" },
  { value: "F450", label: "F450" },
  { value: "F550", label: "F550" },
  { value: "G3500", label: "G3500" },
  { value: "G4500", label: "G4500" },
  { value: "C5500", label: "C5500" },
  { value: "Grand Caravan MVP RE", label: "Grand Caravan MVP SE" },
  { value: "ProMaster 3500", label: "ProMaster 3500" },
  { value: "Sprinter 3500XD", label: "Sprinter 3500XD" },
  { value: "Sprinter 4500", label: "Sprinter 4500" },
];

const fuelTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Gas", label: "Gas" },
  { value: "Diesel", label: "Diesel" },
  { value: "Compressed Natural Gas", label: "Natural Gas" },
  { value: "Propane", label: "Propane" },
  { value: "Ethanol", label: "Ethanol" },
  { value: "Bio Diesel", label: "Bio Diesel" },
  { value: "Electric", label: "Electric" },
];

const engineTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "3.0L", label: "3.0L" },
  { value: "3.5L", label: "3.5L" },
  { value: "3.6L", label: "3.6L" },
  { value: "3.7L", label: "3.7L" },
  { value: "4.8L", label: "4.8L" },
  { value: "5.4L", label: "5.4L" },
  { value: "6.0L", label: "6.0L" },
  { value: "6.2L", label: "6.2L" },
  { value: "6.6L", label: "6.6L" },
  { value: "6.8L", label: "6.8L" },
  { value: "7.3L", label: "7.3L" },
];

const wcTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "12 Passenger 2 WC", label: "12 Passenger 2 WC" },
  { value: "11 Passenger 2 WC", label: "11 Passenger 2 WC" },
  { value: "8 Passenger 2 WC", label: "8 Passenger 2 WC" },
  { value: "6 Passenger 4 WC", label: "6 Passenger 4 WC" },
  { value: "6 Passenger 3 WC", label: "6 Passenger 3 WC" },
  { value: "4 Passenger 4 WC", label: "4 Passenger 4 WC" },
  { value: "4 Passenger 1 WC", label: "4 Passenger 1 WC" },
];

const seatMakeTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "C.E. White", label: "C.E. White" },
  { value: "Freedman", label: "Freedman" },
  { value: "BESI", label: "BESI" },
  { value: "HSM", label: "HSM" },
];

const seatModelTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Activity", label: "Activity" },
  { value: "Bench", label: "Bench" },
  { value: "Freedman Family", label: "Freedman Family" },
  { value: "Mid-Back", label: "Mid-Back" },
  { value: "High-Back", label: "High-Back" },
];

const seatSizeTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: '17.5"', label: '17.5"' },
  { value: '30"', label: '30"' },
  { value: '33"', label: '33"' },
  { value: '35"', label: '35"' },
  { value: '36"', label: '36"' },
  { value: '39"', label: '39"' },
];

const seatUphTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Vinyl", label: "Vinyl" },
  { value: "Cloth", label: "Cloth" },
  { value: "Stain Guard", label: "Stain Guard" },
  { value: "Leathermate", label: "Leathermate" },
];

const seatUphColorTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "Grey", label: "Grey" },
  { value: "Raven Black", label: "Raven Black" },
  { value: "Blue", label: "Blue" },
  { value: "Blue Incense", label: "Blue Incense" },
  { value: "Burgundy", label: "Burgundy" },
  { value: "Gem & Rock", label: "Gem & Rock" },
  { value: "Stellar Arctic", label: "Stellar Arctic" },
  { value: "Tan", label: "Tan" },
  { value: "Milano Black", label: "Milano Black" },
  { value: "Concord Stitch", label: "Concord Stich" },
];

const seatBeltTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "N/A", label: "N/A" },
  { value: "N/I", label: "N/I" },
  { value: "Lap", label: "Lap" },
  { value: "3-point", label: "3-point" },
  { value: "Retractable Lap", label: "Retractable Lap" },
  { value: "Underseat Retractable", label: "Underseat Retractable" },
];

export default function BusBodyForm(props) {
  const {
    formField: {
      inventoryType,
      busType,
      busSize,
      busYear,
      busMake,
      busModel,
      amb,
      wc,
      rearStorage,
      overHeadStorage,
      bodyColor,
      chassisYear,
      chassisMake,
      chassisModel,
      axle,
      engine,
      fuelType,
      gvwr,
      mileage,
      seatMake,
      seatModel,
      seatSize,
      seatUph,
      seatUphColor,
      seatBelt,
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Bus Body Description
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={inventoryType.name}
            label={inventoryType.label}
            data={invTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={busType.name}
            label={busType.label}
            data={busTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SelectField
            name={busSize.name}
            label={busSize.label}
            data={sizeTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={busYear.name}
            label={busYear.label}
            data={yearTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={busMake.name}
            label={busMake.label}
            data={makeTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={busModel.name}
            label={busModel.label}
            data={modelTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField
            type="number"
            name={amb.name}
            label={amb.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={wc.name}
            label={wc.label}
            data={wcTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={rearStorage.name}
            label={rearStorage.label}
            data={rearStorageTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={overHeadStorage.name}
            label={overHeadStorage.label}
            data={overHeadStorageTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={bodyColor.name}
            label={bodyColor.label}
            data={bodyColorTypes}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom>
            Bus Chassis Description
          </Typography>
        </Grid>

        {/* chassis inputs */}

        <Grid item xs={12} sm={3}>
          <SelectField
            name={chassisYear.name}
            label={chassisYear.label}
            data={yearTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={chassisMake.name}
            label={chassisMake.label}
            data={chassisMakeTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={chassisModel.name}
            label={chassisModel.label}
            data={chassisModelTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={axle.name}
            label={axle.label}
            data={axleTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={engine.name}
            label={engine.label}
            data={engineTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={fuelType.name}
            label={fuelType.label}
            data={fuelTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField name={gvwr.name} label={gvwr.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField name={mileage.name} label={mileage.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatMake.name}
            label={seatMake.label}
            data={seatMakeTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatModel.name}
            label={seatModel.label}
            data={seatModelTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatSize.name}
            label={seatSize.label}
            data={seatSizeTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatUph.name}
            label={seatUph.label}
            data={seatUphTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatUphColor.name}
            label={seatUphColor.label}
            data={seatUphColorTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={seatBelt.name}
            label={seatBelt.label}
            data={seatBeltTypes}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";
import { DatePickerField } from "../../FormFields";

const vehicleConditionTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "New", label: "New" },
  { value: "Used", label: "Used" },
];

// const arrivalStatusTypes = [ REMOVED AS REQUESTED 11/21
//   {
//     value: undefined,
//     label: "None",
//   },
//   { value: "In Stock", label: "In Stock" },
//   { value: "Pending", label: "Pending" },
// ];

const currentLocationTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "Customer", label: "Customer" },
  { value: "Factory", label: "Factory" },
  { value: "HBS-Alvarado", label: "HBS-Alvarado" },
  { value: "HBS-Burleson", label: "HBS-Burleson" },
  { value: "HBS-Cleburne", label: "HBS-Cleburne" },
  { value: "HBS-Florida", label: "HBS-Florida" },
  { value: "HBS-Lewisville", label: "HBS-Lewisville" },
  { value: "Salesperson", label: "Salesperson" },
  { value: "Victory Lot", label: "Victory Lot" },
];

const locationStateTypes = [
  {
    value: undefined,
    label: "None",
  },

  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },
  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "MT", label: "MT" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" },
];

const carsonCompleteTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "Y", label: "Y" },
  { value: "N", label: "N" },
];

const makeReadyStandardTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "Basic", label: "Basic" },
  { value: "Standard", label: "Standard" },
  { value: "CPO", label: "CPO" },
];
const makeReadyStatusTypes = [
  {
    value: undefined,
    label: "None",
  },
  { value: "Show Line", label: "Show Line" },
  { value: "In Process", label: "In Process" },
  { value: "On Hold", label: "On Hold" },
  { value: "Unscheduled", label: "Unscheduled" },
];

export default function ChassisForm(props) {
  const {
    formField: {
      stock,
      vehicleCondition,
      vin,
      bodyNumber,
      // arrivalStatus, REMOVED AS REQUESTED 11/21
      currentLocation,
      previousOwner,
      pickUpLocationCity,
      pickUpLocationState,
      chassisOrderDate,
      chassisTargetedCompletion,
      chassisCompletion,
      chassisInvoice,
      chassisAtManufacturer,
      bodyOrderDate,
      bodyTargetedCompletion,
      bodyCompletion,
      bodyInvoice,
      checkInDate,
      showlineDate,
      worksheetPresentationComplete,
      makeReadyStandard,
      makeReadyStatus,
      showlinePhotos,
      stockFolderBuilt,
      stockFolderComplete,
      // floorDate,REMOVED AS REQUESTED 11/21
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Additional Info.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <InputField name={stock.name} label={stock.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={vehicleCondition.name}
            label={vehicleCondition.label}
            data={vehicleConditionTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={vin.name} label={vin.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField
            name={bodyNumber.name}
            label={bodyNumber.label}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}> REMOVED AS REQUESTED 11/21
          <SelectField
            name={arrivalStatus.name}
            label={arrivalStatus.label}
            data={arrivalStatusTypes}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <SelectField
            name={currentLocation.name}
            label={currentLocation.label}
            data={currentLocationTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField
            name={previousOwner.name}
            label={previousOwner.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputField
            name={pickUpLocationCity.name}
            label={pickUpLocationCity.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={pickUpLocationState.name}
            label={pickUpLocationState.label}
            data={locationStateTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePickerField
            name={chassisOrderDate.name}
            label={chassisOrderDate.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={chassisTargetedCompletion.name}
            label={chassisTargetedCompletion.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePickerField
            name={chassisCompletion.name}
            label={chassisCompletion.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={chassisInvoice.name}
            label={chassisInvoice.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePickerField
            name={chassisAtManufacturer.name}
            label={chassisAtManufacturer.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={bodyOrderDate.name}
            label={bodyOrderDate.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={bodyTargetedCompletion.name}
            label={bodyTargetedCompletion.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={bodyCompletion.name}
            label={bodyCompletion.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={bodyInvoice.name}
            label={bodyInvoice.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} md={3}> REMOVED AS REQUESTED 11/21
          <DatePickerField
            name={floorDate.name}
            label={floorDate.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={checkInDate.name}
            label={checkInDate.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePickerField
            name={showlineDate.name}
            label={showlineDate.label}
            format="MM/dd/yy"
            maxDate={new Date("2050/12/31")}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={worksheetPresentationComplete.name}
            label={worksheetPresentationComplete.label}
            data={carsonCompleteTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={makeReadyStandard.name}
            label={makeReadyStandard.label}
            data={makeReadyStandardTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={makeReadyStatus.name}
            label={makeReadyStatus.label}
            data={makeReadyStatusTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={showlinePhotos.name}
            label={showlinePhotos.label}
            data={carsonCompleteTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={stockFolderBuilt.name}
            label={stockFolderBuilt.label}
            data={carsonCompleteTypes}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={stockFolderComplete.name}
            label={stockFolderComplete.label}
            data={carsonCompleteTypes}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Button } from "primereact/button";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type="button"
        icon="pi pi-eject"
        label="Log Out"
        className="p-button-raised p-button-text"
        iconPos="right"
        onClick={() => logout()}
      ></Button>
    </div>
  );
};

export default LogoutButton;

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import Login from "./Login";
import App from "../App";

const MainLogin = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {!isAuthenticated && <Login />}

      {isAuthenticated && <App />}
    </>
  );
};

export default MainLogin;

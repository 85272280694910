import React from "react";
import { Typography } from "@material-ui/core";

// import {Dashboard} from './components/Dashboard';
import { Switch } from "react-router-dom";

function CheckoutSuccess() {
  return (
    <div>
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Thank you!
        </Typography>
        <Typography variant="subtitle1">
          This unit has been added to the inventory. Start selling!
        </Typography>

        <div>
          <Switch>
            {/* <Redirect from='./components/CheckoutSuccess' /> */}
          </Switch>
        </div>
      </React.Fragment>
    </div>
  );
}

export default CheckoutSuccess;

import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    inventoryType,
    busType,
    busSize,
    busMake,
    wc,
    busModel,
    rearStorage,
    overHeadStorage,
    amb,
    bodyColor,
    chassisYear,
    chassisMake,
    chassisModel,
    axle,
    engine,
    fuelType,
    gvwr,
    mileage,
    seatMake,
    seatModel,
    seatSize,
    seatUph,
    seatUphColor,
    seatBelt,
    stock,
    vin,
    bodyNumber,
    // arrivalStatus,  REMOVED AS REQUESTED 11/21
    currentLocation,
    previousOwner,
    pickUpLocationCity,
    pickUpLocationState,
    chassisOrderDate,
    chassisTargetedCompletion,
    chassisCompletion,
    chassisInvoice,
    chassisAtManufacturer,
    bodyOrderDate,
    bodyTargetedCompletion,
    bodyCompletion,
    bodyInvoice,
    // floorDate, REMOVED AS REQUESTED 11/21
    newChassisCost,
    newBodyCost,
    usedBusCost,
    freightCost,
    pdi,
    interestPac,
    adminPac,
    goodWillPac,
    makeReadyProjection,
    makeReadyCost,
    paintBodyProjection,
    paintBodyCost,
    salesPrice,
    rebates,
    totalBusCost,
    totalProdCogs,
    totalCost,
    grossProfit,
    notes,
    checkInDate,
    showlineDate,
    worksheetPresentationComplete,
    makeReadyStandard,
    makeReadyStatus,
    showlinePhotos,
    stockFolderBuilt,
    stockFolderComplete,
  },
} = checkoutFormModel;

const initialFormValues = {
  [inventoryType.name]: "",
  [busType.name]: "",
  [busSize.name]: "",
  [busMake.name]: "",
  [wc.name]: "",
  [busModel.name]: "",
  [rearStorage.name]: "",
  [overHeadStorage.name]: "",
  [amb.name]: "",
  [bodyColor.name]: "",
  [chassisYear.name]: "",
  [chassisMake.name]: "",
  [chassisModel.name]: "",
  [axle.name]: "",
  [engine.name]: "",
  [fuelType.name]: "",
  [gvwr.name]: "",
  [mileage.name]: "",
  [seatMake.name]: "",
  [seatModel.name]: "",
  [seatSize.name]: "",
  [seatUph.name]: "",
  [seatUphColor.name]: "",
  [seatBelt.name]: "",
  [stock.name]: "",
  [vin.name]: "",
  [bodyNumber.name]: "",
  // [arrivalStatus.name]: "", REMOVED AS REQUESTED 11/21
  [currentLocation.name]: "",
  [previousOwner.name]: "",
  [pickUpLocationCity.name]: "",
  [pickUpLocationState.name]: "",
  [chassisOrderDate.name]: "",
  [chassisTargetedCompletion.name]: "",
  [chassisCompletion.name]: "",
  [chassisInvoice.name]: "",
  [chassisAtManufacturer.name]: "",
  [bodyOrderDate.name]: "",
  [bodyTargetedCompletion.name]: "",
  [bodyCompletion.name]: "",
  [bodyInvoice.name]: "",
  // [floorDate.name]: "", REMOVED AS REQUESTED 11/21
  [newChassisCost.name]: "",
  [newBodyCost.name]: "",
  [usedBusCost.name]: "",
  [freightCost.name]: "",
  [pdi.name]: "",
  [interestPac.name]: "",
  [adminPac.name]: "",
  [goodWillPac.name]: "",
  [makeReadyStatus.name]: "",
  [makeReadyProjection.name]: "",
  [makeReadyCost.name]: "",
  [paintBodyProjection.name]: "",
  [paintBodyCost.name]: "",
  [salesPrice.name]: "",
  [rebates.name]: "",
  [totalBusCost.name]: "",
  [totalProdCogs.name]: "",
  [totalCost.name]: "",
  [grossProfit.name]: "",
  [notes.name]: "",
  [checkInDate.name]: "",
  [showlineDate.name]: "",
  [worksheetPresentationComplete.name]: "",
  [makeReadyStandard.name]: "",
  [makeReadyStatus.name]: "",
  [showlinePhotos.name]: "",
  [stockFolderBuilt.name]: "",
  [stockFolderComplete.name]: "",
};

export default initialFormValues;

import { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import React from "react";
import "./App.css";

// import dynamic from "next/dynamic";
import axios from "axios";

/* Format Date Cells */
const dateFormatter = (params) => {
  return new Date(params.value).toLocaleDateString();
};
/* Format Currency Cells */
const currencyFormatter = (params) => {
  return "$" + formatNumber(params.value);
};

const formatNumber = (number) => {
  return Math.floor(number).toLocaleString();
};

function Allinventory() {
  const gridRef = useRef();
  // const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const rowHeight = 30;

  const [gridApi, setGridApi] = useState();
  const [columnApi, setcolumnApi] = useState();

  // Fetch data & update rowData state
  useEffect(() => {
    fetch("https://mern-heroku-netlify.herokuapp.com/api/buses/") // Fetch data from server
      .then((result) => result.json()) // Convert to JSON
      .then((rowData) => setRowData(rowData)); // Update state of `rowData`
  }, []);

  const onGridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
  };

  const gridOptions = {
    rowClassRules: {
      "rag-amber": "data.inventoryType = Sold",
    },
  };

  // onCellValueChanged = (e) => {
  //   console.log(e); //to test it
  //   e.data.modified = true;
  // };

  // Save on cell edits
  const onCellValueChanged = ({ data }) => {
    console.log("Data after change is", data);
    axios
      .put(
        `https://mern-heroku-netlify.herokuapp.com/api/buses/${data._id}`,
        data
      )
      .then((response) => data)
      .catch((error) => {
        console.log(error);
      });
  };

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Save on cell edits
  // const getAllData = () => {
  //   let rowData = [];
  //   gridApi.forEachNode((node) => rowData.push(node.data));
  //   return rowData;
  // };

  /* COLUMN DEFINITIONS, OPTIONS AND PROPERTIES */
  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Inventory Type",
      field: "inventoryType",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "Stock",
          "Stock On Order",
          "Sold",
          "Sold On Order",
          "Aged",
          "Consignment",
          "Sale Pending",
          "FB Marketplace",
        ],
      },
    },
    {
      headerName: "Bus Type",
      field: "busType",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "Commercial",
          "MFSAB",
          "MPV",
          "School Bus",
          "Specialty",
          "Van",
        ],
      },
    },
    {
      headerName: "Vehicle Condition",
      field: "vehicleCondition",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["New", "Used"],
      },
    },
    {
      headerName: "Stock#",
      field: "stock",
      width: 100,
      pinned: "left",

      filter: true,
    },

    {
      headerName: "Bus Year",
      field: "busYear",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "1995",
          "1996",
          "1997",
          "1998",
          "1999",
          "2000",
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
          "2028",
          "2029",
          "2030",
        ],
      },
    },

    {
      headerName: "Bus Make",
      field: "busMake",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Blue Bird",
          "Caleche",
          "Collins",
          "Corbeil",
          "Diamond",
          "Elkhart Custom Designs",
          "Eldorado",
          "Endera",
          "FR Conversions",
          "Federal",
          "Ford",
          "General Coach",
          "Girardin",
          "Glaval",
          "Goshen",
          "International",
          "LA West",
          "Mercedes",
          "Mid Bus",
          "Pegasus Bus",
          "Peterbilt",
          "Revability",
          "StarCraft",
          "StarCraft",
          "StarTrans",
          "Thomas",
          "Turtle Top",
          "TWR",
          "US Bus",
          "VanCon",
          "Winnebago",
        ],
      },
    },

    {
      headerName: "Bus Model",
      field: "busModel",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "579",
          "389",
          "Advance",
          "Advantage",
          "Advantage 220",
          "Advantage 770",
          "Aero Elite",
          "Aerotech 240",
          "Allstar",
          "Allstar XL",
          "Bantam Hi-Top",
          "Bantam Lo-Top",
          "DE516",
          "DH400",
          "DH416",
          "DH500",
          "DH516",
          "Dodge Grand Caravan",
          "Executive Shuttle",
          "EZ Trans",
          "Impulse 220",
          "Legacy",
          "Lift Equip Van",
          "Low-Floor- Commercial",
          "MBII",
          "Metro Link",
          "Micro Bird",
          "Minotour",
          "MyBus",
          "Navette",
          "Nugget",
          "Odyssey XL",
          "Panoramic",
          "ProMaster",
          "Prodigy",
          "SH400",
          "SL400",
          "Spirit",
          "Sprinter",
          "Super Bantam",
          "Terra Transit Light Duty",
          "Terra Transit Medium Duty",
          "Terra Transit Heavy Duty",
          "TH400",
          "TH500",
          "Titan II",
          "TL400",
          "Transit Connect XLT",
          "Transit XLT",
          "Valkyrie",
          "VanTerra XLT",
          "VIP 2000",
          "VIP 2200",
          "VIP 2500",
          "VIP 2800",
          "VIP 3500",
        ],
      },
    },

    { headerName: "Amb", field: "amb", sortable: true, filter: true },

    {
      headerName: "WC",
      field: "wc",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["0", "1", "2", "3", "4", "5", "6", "7"],
      },
    },

    {
      headerName: "Rear Storage",
      field: "rearStorage",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["N/A", "Dedicated", "Non-Dedicated"],
      },
    },

    {
      headerName: "Over Head Storage",
      field: "overHeadStorage",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["YES", "NO"],
      },
    },

    {
      headerName: "Body Color",
      field: "bodyColor",
      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["N/A", "White", "Black", "Yellow", "Other"],
      },
    },

    {
      headerName: "Chassis Year",
      field: "chassisYear",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "1995",
          "1996",
          "1997",
          "1998",
          "1999",
          "2000",
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
          "2024",
          "2025",
          "2026",
          "2027",
          "2028",
          "2029",
          "2030",
        ],
      },
    },

    {
      headerName: "Chassis Make",
      field: "chassisMake",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Chevrolet",
          "Dodge",
          "Ford",
          "Freightliner",
          "GMC",
          "International",
          "Ram",
          "Mercedes-Benz",
        ],
      },
    },

    {
      headerName: "Chassis Model",
      field: "chassisModel",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "T250",
          "T350",
          "E350",
          "E450",
          "F450",
          "F550",
          "Freightliner",
          "G3500",
          "G4500",
          "C5500",
          "Grand Caravan MVP RE",
          "Pro Master 2500",
          "Pro Master 3500",
          "Sprinter 2500",
          "Sprinter 3500XD",
          "Sprinter 4500",
        ],
      },
    },

    {
      headerName: "Axle",
      field: "axle",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["SRW", "DRW"],
      },
    },

    {
      headerName: "Engine",
      field: "engine",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "3.0L",
          "3.5L",
          "3.6L",
          "3.7L",
          "4.8L",
          "5.4L",
          "6.0L",
          "6.2L",
          "6.3L",
          "6.6L",
          "6.8L",
          "7.3L",
        ],
      },
    },

    {
      headerName: "Fuel Type",
      field: "fuelType",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Gas",
          "Diesel",
          "Compressed Natural Gas",
          "Propane",
          "Ethanol",
          "Bio Diesel",
          "Electric",
        ],
      },
    },

    { headerName: "GVWR", field: "gvwr", filter: true },
    { headerName: "Mileage", field: "mileage", filter: true },

    {
      headerName: "Seat Model",
      field: "seatModel",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Activity",
          "Bench",
          "Freedman Family",
          "Mid-Back",
          "High-Back",
        ],
      },
    },

    {
      headerName: "Seat Uph",
      field: "seatUph",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["N/A", "Cloth", "Leathermate", "Stain Guard", "Vinyl"],
      },
    },

    {
      headerName: "Seat Uph Color",
      field: "seatUphColor",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Blue",
          "Blue Incense",
          "Burgundy",
          "Concord Stitch",
          "Gem & Rock",
          "Grey",
          "Milano Black",
          "Raven Black",
          "Stellar Arctic",
          "Tan",
        ],
      },
    },

    {
      headerName: "Seat Belt",
      field: "seatBelt",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "N/A",
          "Lap",
          "3-point",
          "Retractable Lap",
          "Underseat Retractable",
        ],
      },
    },

    { headerName: "VIN#", field: "vin", sortable: true, filter: true },
    {
      headerName: "Body Number",
      field: "bodyNumber",

      filter: true,
    },

    {
      headerName: "Current Location",
      field: "currentLocation",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "HBS-Alvarado",
          "HBS-Burleson",
          "HBS-Cleburne",
          "HBS-Lewisville",
          "HBS-Florida",
          "Customer",
          "Factory",
          "Salesperson",
          "Victory Lot",
        ],
      },
    },

    {
      headerName: "Previous Owner",
      field: "previousOwner",

      filter: true,
    },
    {
      headerName: "Pick-up City",
      field: "pickUpLocationCity",

      filter: true,
    },

    {
      headerName: "Pick-up State",
      field: "pickUpLocationState",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "AL",
          "AK",
          "AZ",
          "AR",
          "CA",
          "CO",
          "CT",
          "DE",
          "FL",
          "GA",
          "HI",
          "ID",
          "IL",
          "IN",
          "IA",
          "KS",
          "KY",
          "LA",
          "ME",
          "MD",
          "MA",
          "MI",
          "MN",
          "MS",
          "MO",
          "MT",
          "NE",
          "NV",
          "NH",
          "NJ",
          "NM",
          "NY",
          "NC",
          "ND",
          "OH",
          "OK",
          "OR",
          "PA",
          "RI",
          "SC",
          "SD",
          "TN",
          "TX",
          "UT",
          "VT",
          "VA",
          "WA",
          "WV",
          "WI",
          "WY",
        ],
      },
    },

    {
      headerName: "Chassis Order Date",
      field: "chassisOrderDate",
      valueFormatter: dateFormatter,
      valueSetter: (params) => {
        params.data.chassisOrderDate = params.newValue;
        return true;
      },
      cellEditor: "agDateStringCellEditor",
      cellEditorParams: {
        min: "1999-01-01",
        min: "2019-12-31",
      },
    },
    {
      headerName: "Chassis Targeted Completion",
      field: "chassisTargetedCompletion",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Chassis Completion",
      field: "chassisCompletion",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Chassis Invoice",
      field: "chassisInvoice",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Chassis At Manufacturer",
      field: "chassisAtManufacturer",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },

    {
      headerName: "Body Order Date",
      field: "bodyOrderDate",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Body Targeted Completion",
      field: "bodyTargetedCompletion",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Body Completion",
      field: "bodyCompletion",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Body Invoice",
      field: "bodyInvoice",
      valueFormatter: dateFormatter,
      cellEditor: "agDateStringCellEditor",
    },
    {
      headerName: "Days Owned",
      field: "daysOwned",
      editable: false,
      filter: true,
      valueGetter: (params) => {
        let d1 = new Date(),
          d2 = new Date(params.data.bodyInvoice),
          d3 = new Date(params.data.soldDate);

        if (params.data.inventoryType !== "Sold") {
          return Math.round((d1 - d2).toString() / 1000 / 60 / 60 / 24);
        } else {
          return Math.round((d1 - d3).toString() / 1000 / 60 / 60 / 24);
        }
      },
      // valueSetter: (params) => {
      //   params.data.daysOwned = params.newValue;
      //   return true;
      // },

      cellStyle: function (params) {
        if (params.value < 90) {
          return { color: "blue" };
        } else if (params.value > 90 && params.value < 121) {
          return { color: "red" };
        } else {
          return { color: "red" };
        }
      },
    },

    {
      headerName: "Chassis Cost",
      field: "newChassisCost",
      valueFormatter: currencyFormatter,
      filter: true,
    },
    {
      headerName: "Body Cost",
      field: "newBodyCost",
      valueFormatter: currencyFormatter,
      filter: true,
    },

    {
      headerName: "Total Bus Cost",
      field: "totalBusCost",
      editable: false,
      valueFormatter: currencyFormatter,
      filter: true,
      valueGetter: (params) => {
        return params.data.newChassisCost + params.data.newBodyCost;
      },
    },
    {
      headerName: "Estimated Freight Cost",
      field: "freightCost",
      valueFormatter: currencyFormatter,
      filter: true,
    },
    {
      headerName: "Pdi",
      field: "pdi",
      valueFormatter: currencyFormatter,
      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["350", "500", "750"],
      },
    },
    {
      headerName: "Interest Pac",
      field: "interestPac",
      valueFormatter: currencyFormatter,
      filter: true,
    },
    {
      headerName: "Admin PAc",
      field: "adminPac",
      valueFormatter: currencyFormatter,
      filter: true,
      editable: false,
    },
    {
      headerName: "Good Will Pac",
      field: "goodWillPac",
      valueFormatter: currencyFormatter,
      filter: true,
      editable: false,
    },
    {
      headerName: "Make Ready Projection",
      field: "makeReadyProjection",
      valueFormatter: currencyFormatter,
      filter: true,

      cellStyle: (params) =>
        params.data.makeReadyStatus === "Show Line"
          ? { color: "lightgray" }
          : { color: "black" },
    },

    {
      headerName: "Make Ready Cost",
      field: "makeReadyCost",
      valueFormatter: currencyFormatter,
      filter: true,

      cellStyle: (params) =>
        params.data.makeReadyStatus === "Show Line"
          ? { color: "blue" }
          : { color: "lightgray" },
    },

    {
      headerName: "Paint/Body Projection",
      field: "paintBodyProjection",
      valueFormatter: currencyFormatter,

      filter: true,

      cellStyle: (params) =>
        params.data.makeReadyStatus === "Show Line"
          ? { color: "lightgray" }
          : { color: "black" },
    },
    {
      headerName: "Paint/Body Cost",
      field: "paintBodyCost",
      valueFormatter: currencyFormatter,
      filter: true,

      cellStyle: (params) =>
        params.data.makeReadyStatus === "Show Line"
          ? { color: "blue" }
          : { color: "lightgray" },
    },

    {
      headerName: "Make Ready Status",
      field: "makeReadyStatus",
      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Show Line", "In Process", "On Hold", "Unscheduled"],
      },
    },

    {
      headerName: "Check-in Date",
      field: "checkInDate",
      valueFormatter: dateFormatter,
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "Show Line Date",
      field: "showlineDate",
      valueFormatter: dateFormatter,
      cellEditor: "agDateCellEditor",
    },
    {
      headerName: "Worksheet Presentation Complete",
      field: "worksheetPresentationComplete",
      filter: true,
    },
    {
      headerName: "Make Ready Standard",
      field: "makeReadyStandard",

      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Basic", "CPO", "New", "Standard"],
      },
    },
    {
      headerName: "Show Line Photos",
      field: "showlinePhotos",
      filter: true,
    },
    {
      headerName: "Stock Folder Built",
      field: "stockFolderBuilt",
      filter: true,
    },
    {
      headerName: "Stock Folder Complete",
      field: "stockFolderComplete",
      filter: true,
    },

    {
      headerName: "Total Cost",
      field: "totalCost",
      valueFormatter: currencyFormatter,
      filter: true,
      editable: false,

      valueGetter: (params) => {
        let firstStepFinalProdCogs = 0;
        let secondStepFinalProdCogs = 0;
        let actualProdCogs = 0;
        let staticCosts =
          params.data.freightCost +
          params.data.pdi +
          params.data.interestPac +
          params.data.adminPac +
          params.data.goodWillPac;

        if (
          params.data.makeReadyStatus === "Show Line" ||
          params.data.makeReadyStatus === "Showline"
        ) {
          firstStepFinalProdCogs = params.data.makeReadyCost;
          secondStepFinalProdCogs = params.data.paintBodyCost;
        } else {
          firstStepFinalProdCogs = params.data.makeReadyProjection;
          secondStepFinalProdCogs = params.data.paintBodyProjection;
        }
        actualProdCogs =
          staticCosts + firstStepFinalProdCogs + secondStepFinalProdCogs;
        // return params.getValue("totalProdCogs") + params.getValue("totalBusCost"); - original calculation for total cost
        return actualProdCogs + params.getValue("totalBusCost");
      },
    },
    {
      headerName: "Sales Price",
      field: "salesPrice",
      valueFormatter: currencyFormatter,
      filter: true,
    },

    {
      headerName: "Gross Profit",
      field: "grossProfit",
      editable: false,
      valueFormatter: currencyFormatter,
      filter: true,

      valueGetter: (params) => {
        return params.data.salesPrice - params.getValue("totalCost");
      },
      cellStyle: function (params) {
        if (params.value < 0) {
          //mark police cells as red
          return { color: "red" };
        } else if (params.value < 5999) {
          return { color: "black" };
        } else {
          return { color: "green" };
        }
      },
    },

    {
      headerName: "Rebates",
      field: "rebates",
      editable: false,
      valueFormatter: currencyFormatter,
      filter: true,
    },
    {
      headerName: "Revised Gp",
      field: "revisedGp",
      editable: false,
      valueFormatter: currencyFormatter,
      filter: true,

      valueGetter: (params) => {
        return params.getValue("grossProfit") + params.data.rebates;
      },
      valueSetter: (params) => {
        params.value = params.newValue;
        return true;
      },
      cellStyle: function (params) {
        if (params.value < 0) {
          //mark police cells as red
          return { color: "red" };
        } else if (params.value < 5999) {
          return { color: "black" };
        } else {
          return { color: "green" };
        }
      },

      minWidth: 90,
      pinned: "right",
    },

    { headerName: "Notes", field: "notes", sortable: true, filter: true },
    {
      headerName: "Sold Date",
      cellRenderer: (data) => {
        return data.value ? new Date(data.value).toLocaleDateString() : "";
      },
      field: "soldDate",
      valueFormatter: dateFormatter,
      cellEditor: "agDateCellEditor",
    },
  ]);

  const defaultColDef = useMemo(() => ({
    filter: true, // Enable filtering on all columns
    editable: true, // Enable editing on all cells
    sortable: true, // Enable sorting on all columns
  }));

  // const getRowId = useCallback((params) => {
  //   console.log(params);

  //   return params.data.id;
  // });

  /*    QUICK FILTER */

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  return (
    <main>
      <div className=" ag-theme-quartz" style={{ height: 630 }}>
        {/* The AG Grid component */}
        <div className="example-wrapper">
          <div className="example-header">
            <span className="filtersearch">Quick Filter:</span>
            <input
              className="search-input"
              type="text"
              id="filter-text-box"
              placeholder="Filter..."
              onInput={onFilterTextBoxChanged}
            />
            {/* <button type="button" onClick={this.onButtonClick}>
              Selected Rows
            </button> */}
          </div>

          <div style={gridStyle} className={"ag-theme-quartz"}></div>
        </div>

        <AgGridReact
          // onGridReady={onGridReady}
          ref={gridRef}
          rowHeight={rowHeight}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          // pagination={true} // Enable Pagination
          // animateRows={true} // Enable animations of rows
          rowSelection="multiple" // Enable Row selection
          onSelectionChanged={(event) => console.log("Row Selected!")}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </main>
  );
}

export default Allinventory;

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Button } from "primereact/button";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="login-body">
      <div className="body-container">
        <div className="p-grid p-nogutter">
          <div className="p-col-12 p-lg-6 left-side">
            <img
              src="assets\layout\images\logo-dark.png"
              alt="apollo-layout"
              className="logo"
            />
            <h1>HBS Inventory</h1>
            <div></div>
            <div>
              <Button
                type="button"
                label="Login"
                className="p-button-raised p-button-text"
                iconPos="right"
                onClick={() => loginWithRedirect({})}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

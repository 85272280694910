import React from "react";
import ReactDOM from "react-dom";
// import Main from './Components/Main';
import { HashRouter } from "react-router-dom";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "prismjs/themes/prism-coy.css";
import { Auth0Provider } from "./react-auth0-spa";
import { authConfig } from "./auth.config";
import Main from "./Components/Main";

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <HashRouter>
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Main />
    </Auth0Provider>
  </HashRouter>,
  document.getElementById("root")
);

const checkoutForm = {
  formId: "checkoutForm",
  formField: {
    inventoryType: {
      name: "inventoryType",
      label: "Inventory Type*",
      requiredErrorMsg: "Inventory Type is required",
    },
    busType: {
      name: "busType",
      label: "Bus Type*",
      requiredErrorMsg: "Bus Type is required",
    },
    busSize: {
      name: "busSize",
      label: "Bus Size*",
      requiredErrorMsg: "Bus Size is required",
    },
    busYear: {
      name: "busYear",
      label: "Bus Year",
      requiredErrorMsg: "Bus Year is required",
    },
    busMake: {
      name: "busMake",
      label: "Bus Make*",
      requiredErrorMsg: "Bus Make is required",
    },
    amb: {
      name: "amb",

      label: "Amb",
      requiredErrorMsg: "Amb is required",

    },
    wc: {
      name: "wc",
      label: "WC",
      requiredErrorMsg: "WC is required",
    },
    rearStorage: {
      name: "rearStorage",
      label: "Rear Storage",
      requiredErrorMsg: "Rear Storage is required",
    },
    overHeadStorage: {
      name: "overHeadStorage",
      label: "Over Head Storage",
      requiredErrorMsg: "Over Head Storage is required",
    },
    bodyColor: {
      name: "bodyColor",
      label: "Body Color",
      requiredErrorMsg: "Body Color is required",
    },
    busModel: {
      name: "busModel",
      label: "Bus Model*",
      requiredErrorMsg: "Bus Model is required",
    },
    chassisYear: {
      name: "chassisYear",
      label: "Chassis Year*",
      requiredErrorMsg: "Chassis Year is required",
    },
    chassisMake: {
      name: "chassisMake",
      label: "Chassis Make*",
      requiredErrorMsg: "Chassis Make is required",
    },
    chassisModel: {
      name: "chassisModel",
      label: "Chassis Model*",
      requiredErrorMsg: "Chassis Model is required",
    },
    axle: {
      name: "axle",
      label: "Axle*",
      requiredErrorMsg: "Axle is required",
    },
    engine: {
      name: "engine",
      label: "Engine",
      requiredErrorMsg: "Engine is required",
    },
    fuelType: {
      name: "fuelType",
      label: "Fuel",
      requiredErrorMsg: "Fuel is required",
    },
    gvwr: {
      name: "gvwr",
      label: "GVWR",
      requiredErrorMsg: "GVWR is required",
    },
    mileage: {
      name: "mileage",
      label: "Mileage",
      requiredErrorMsg: "Mileage is required",
    },
    seatMake: {
      name: "seatMake",
      label: "Seat Make",
      requiredErrorMsg: "Seat Make is required",
    },
    seatModel: {
      name: "seatModel",
      label: "Seat Model",
      requiredErrorMsg: "Seat Model is required",
    },
    seatSize: {
      name: "seatSize",
      label: "Seat Size",
      requiredErrorMsg: "Seat Size is required",
    },
    seatUph: {
      name: "seatUph",
      label: "Seat Upholstery",
      requiredErrorMsg: "Seat Upholstery is required",
    },
    seatUphColor: {
      name: "seatUphColor",
      requiredErrorMsg: "Upholstery Color is required",
    },
    seatBelt: {
      name: "seatBelt",
      label: "Seat Belt",
      requiredErrorMsg: "Seat Belt is required",
    },
    vehicleCondition: {
      name: "vehicleCondition",
      label: "Vehicle Condition",
      requiredErrorMsg: "Vehicle Condition is required",
    },

    stock: {
      name: "stock",
      label: "Stock#",
      requiredErrorMsg: "Stock# is required",
    },

    vin: {
      name: "vin",
      label: "VIN#",
      requiredErrorMsg: "VIN# is required",
    },

    bodyNumber: {
      name: "bodyNumber",
      label: "Body Number",
      requiredErrorMsg: "Body Number is required",
    },

    // arrivalStatus: { REMOVED AS REQUESTED 11/21
    //   name: "arrivalStatus",
    //   label: "Arrival Status",
    //   requiredErrorMsg: "Arrival Status is required",
    // },

    currentLocation: {
      name: "currentLocation",
      label: "Current Location",
      requiredErrorMsg: "Current Location is required",
    },
    previousOwner: {
      name: "previousOwner",
      label: "Previous Owner",
      requiredErrorMsg: "Previous Owner is required",
    },
    pickUpLocationCity: {
      name: "pickUpLocationCity",
      label: "Pick-up Location City",
      requiredErrorMsg: "Pick-up Location City is required",
    },
    pickUpLocationState: {
      name: "pickUpLocationState",
      label: "Pick-up Location State",
      requiredErrorMsg: "Pick-up Location State is required",
    },
    chassisOrderDate: {
      name: "chassisOrderDate",
      label: "Chassis Order Date",
      requiredErrorMsg: "Chassis Order Date is required",
    },


    chassisTargetedCompletion: {
      name: "chassisTargetedCompletion",
      label: "Chassis Targeted Completion",
      requiredErrorMsg: "Chassis Targeted Completion is required",

    },

    chassisCompletion: {
      name: "chassisCompletion",
      label: "Chassis Completion",
      requiredErrorMsg: "Chassis Completion is required",
    },

    chassisInvoice: {
      name: "chassisInvoice",
      label: "Chassis Invoice",
      requiredErrorMsg: "Chassis Invoice is required",
    },


    chassisAtManufacturer: {
      name: "chassisAtManufacturer",
      label: "Chassis At Manufacturer",
      requiredErrorMsg: "Chassis At Manufacturer is required",

    },

    bodyOrderDate: {
      name: "bodyOrderDate",
      label: "Body Order Date",
      requiredErrorMsg: "Body Order Date is required",
    },


    bodyTargetedCompletion: {
      name: "bodyTargetedCompletion",
      label: "Body Targeted Completion",
      requiredErrorMsg: "Body Targeted Completion is required",

    },

    bodyCompletion: {
      name: "bodyCompletion",
      label: "Body Completion",
      requiredErrorMsg: "Body  Completion is required",
    },

    bodyInvoice: {
      name: "bodyInvoice",
      label: "Body Invoice",
      requiredErrorMsg: "Body Invoice is required",
    },


    // floorDate: { REMOVED AS REQUESTED 11/21
    //   name: "floorDate",
    //   label: "Floor Date",
    //   requiredErrorMsg: "Floor Date is required",
    // },


    newChassisCost: {
      name: "newChassisCost",
      label: "New Chassis Cost",
    },

    newBodyCost: {
      name: "newBodyCost",
      label: "New Body Cost",
    },

    usedBusCost: {
      name: "usedBusCost",
      label: "Used Bus Cost",
    },

    freightCost: {
      name: "freightCost",
      label: "Freight Cost",
    },

    pdi: {
      name: "pdi",
      label: "PDI Cost",
      requiredErrorMsg: "PDI cost required",
    },

    interestPac: {
      name: "interestPac",
      label: "Interest Pac",
    },

    adminPac: {
      name: "adminPac",
      label: "Admin Pac",
    },

    goodWillPac: {
      name: "goodWillPac",
      label: "Good Will Pac",
    },



    makeReadyCost: {
      name: "makeReadyCost",
      label: "Make Ready Cost",
    },

    makeReadyProjection: {
      name: "makeReadyProjection",
      label: "Make Ready Projection",
    },

    paintBodyProjection: {
      name: "paintBodyProjection",
      label: "Paint/Body Projection",
    },

    paintBodyCost: {
      name: "paintBodyCost",
      label: "Paint/Body Cost",
    },

    rebates: {
      name: "rebates",
      label: "Rebates",
    },

    salesPrice: {
      name: "salesPrice",
      label: "Sales Price",
    },

    totalBusCost: {
      name: "totalBusCost",
      label: "Total Bus Cost",
    },

    totalProdCogs: {
      name: "totalProdCogs",
      label: "Total Prod Cogs",
    },

    totalCost: {
      name: "totalCost",
      label: "Total Cost",
    },

    grossProfit: {
      name: "grossProfit",
      label: "Gross Profit",
    },

    notes: {
      name: "notes",
      label: "Notes",
    },



    checkInDate: {
      name: "checkInDate",
      label: "Check-in Date",
    },

    showlineDate: {
      name: "showlineDate",
      label: "Show Line Date",
    },

    worksheetPresentationComplete: {
      name: "worksheetPresentationComplete",
      label: "Worksheet Presentation Complete",
    },

    makeReadyStandard: {
      name: "makeReadyStandard",
      label: "Make Ready Standard",
    },
    makeReadyStatus: {
      name: "makeReadyStatus",
      label: "Make Ready Status",
    },
    showlinePhotos: {
      name: "showlinePhotos",
      label: "Show Line Photos",
    },
    stockFolderBuilt: {
      name: "stockFolderBuilt",
      label: "Stock Folder Built",
    },
    stockFolderComplete: {
      name: "stockFolderComplete",
      label: "Stock Folder Complete",
    },

  },
};

export default checkoutForm;

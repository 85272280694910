export const columnDefs = [
  {
    headerName: "Inventory Type",
    field: "inventoryType",
    sortable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "Stock",
        "Stock On Order",
        "Sold",
        "Sold On Order",
        "Aged",
        "Consignment",
        "Sale Pending",
        "FB Marketplace",
      ],
    },
  },
  {
    headerName: "Bus Type",
    field: "busType",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["Commercial", "MFSAB", "MPV", "School Bus", "Specialty", "Van"],
    },
  },
  {
    headerName: "Vehicle Condition",
    field: "vehicleCondition",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["New", "Used"],
    },
  },
  {
    headerName: "Stock#",
    field: "stock",
    width: 100,
    pinned: "left",
    sortable: true,
    filter: true,
  },

  {
    headerName: "Bus Year",
    field: "busYear",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
    },
  },

  {
    headerName: "Bus Make",
    field: "busMake",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Blue Bird",
        "Caleche",
        "Collins",
        "Corbeil",
        "Diamond",
        "Elkhart Custom Designs",
        "Eldorado",
        "Endera",
        "FR Conversions",
        "Federal",
        "Ford",
        "General Coach",
        "Girardin",
        "Glaval",
        "Goshen",
        "International",
        "LA West",
        "Mercedes",
        "Mid Bus",
        "Pegasus Bus",
        "Peterbilt",
        "Revability",
        "StarCraft",
        "StarCraft",
        "StarTrans",
        "Thomas",
        "Turtle Top",
        "TWR",
        "US Bus",
        "VanCon",
        "Winnebago",
      ],
    },
  },

  {
    headerName: "Bus Model",
    field: "busModel",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "579",
        "389",
        "Advance",
        "Advantage",
        "Advantage 220",
        "Advantage 770",
        "Aero Elite",
        "Aerotech 240",
        "Allstar",
        "Allstar XL",
        "Bantam Hi-Top",
        "Bantam Lo-Top",
        "DE516",
        "DH400",
        "DH416",
        "DH500",
        "DH516",
        "Dodge Grand Caravan",
        "Executive Shuttle",
        "EZ Trans",
        "Impulse 220",
        "Legacy",
        "Lift Equip Van",
        "Low-Floor- Commercial",
        "MBII",
        "Metro Link",
        "Micro Bird",
        "Minotour",
        "MyBus",
        "Navette",
        "Nugget",
        "Odyssey XL",
        "Panoramic",
        "ProMaster",
        "Prodigy",
        "SH400",
        "SL400",
        "Spirit",
        "Sprinter",
        "Super Bantam",
        "Terra Transit Light Duty",
        "Terra Transit Medium Duty",
        "Terra Transit Heavy Duty",
        "TH400",
        "TH500",
        "Titan II",
        "TL400",
        "Transit Connect XLT",
        "Transit XLT",
        "Valkyrie",
        "VanTerra XLT",
        "VIP 2000",
        "VIP 2200",
        "VIP 2500",
        "VIP 2800",
        "VIP 3500",
      ],
    },
  },

  { headerName: "Capacity", field: "amb", sortable: true, filter: true },

  {
    headerName: "WC",
    field: "wc",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["0", "1", "2", "3", "4", "5", "6", "7"],
    },
  },

  {
    headerName: "Rear Storage",
    field: "rearStorage",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["N/A", "Dedicated", "Non-Dedicated"],
    },
  },

  {
    headerName: "Over Head Storage",
    field: "overHeadStorage",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["YES", "NO"],
    },
  },

  {
    headerName: "Body Color",
    field: "bodyColor",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["N/A", "White", "Black", "Yellow", "Other"],
    },
  },

  {
    headerName: "Chassis Year",
    field: "chassisYear",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "1995",
        "1996",
        "1997",
        "1998",
        "1999",
        "2000",
        "2001",
        "2002",
        "2003",
        "2004",
        "2005",
        "2006",
        "2007",
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
      ],
    },
  },

  {
    headerName: "Chassis Make",
    field: "chassisMake",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Chevrolet",
        "Dodge",
        "Ford",
        "Freightliner",
        "GMC",
        "International",
        "Ram",
        "Mercedes-Benz",
      ],
    },
  },

  {
    headerName: "Chassis Model",
    field: "chassisModel",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "T250",
        "T350",
        "E350",
        "E450",
        "F450",
        "F550",
        "Freightliner",
        "G3500",
        "G4500",
        "C5500",
        "Grand Caravan MVP RE",
        "Pro Master 2500",
        "Pro Master 3500",
        "Sprinter 2500",
        "Sprinter 3500XD",
        "Sprinter 4500",
      ],
    },
  },

  {
    headerName: "Axle",
    field: "axle",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["SRW", "DRW"],
    },
  },

  {
    headerName: "Engine",
    field: "engine",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "3.0L",
        "3.5L",
        "3.6L",
        "3.7L",
        "4.8L",
        "5.4L",
        "6.0L",
        "6.2L",
        "6.3L",
        "6.6L",
        "6.8L",
        "7.3L",
      ],
    },
  },

  {
    headerName: "Fuel Type",
    field: "fuelType",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Gas",
        "Diesel",
        "Compressed Natural Gas",
        "Propane",
        "Ethanol",
        "Bio Diesel",
        "Electric",
      ],
    },
  },

  { headerName: "GVWR", field: "gvwr", sortable: true, filter: true },
  { headerName: "Mileage", field: "mileage", sortable: true, filter: true },

  // {
  //   headerName: "Seat Make",
  //   field: "seatMake",
  //   sortable: true,
  //   filter: true,
  //   cellEditor: "agSelectCellEditor",
  //   cellEditorParams: {
  //     values: ["N/A", "C.E. White", "Freedman", "BESI", "HSM"],
  //   },
  // },

  {
    headerName: "Seat Model",
    field: "seatModel",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Activity",
        "Bench",
        "Freedman Family",
        "Mid-Back",
        "High-Back",
      ],
    },
  },

  // {
  //   headerName: "Seat Size",
  //   field: "seatSize",
  //   sortable: true,
  //   filter: true,
  //   cellEditor: "agSelectCellEditor",
  //   cellEditorParams: {
  //     values: ["N/A", '17.5"', '30"', '33"', '35"', '36"', '39"'],
  //   },
  // },

  {
    headerName: "Seat Uph",
    field: "seatUph",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["N/A", "Cloth", "Leathermate", "Stain Guard", "Vinyl"],
    },
  },

  {
    headerName: "Seat Uph Color",
    field: "seatUphColor",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Blue",
        "Blue Incense",
        "Burgundy",
        "Concord Stitch",
        "Gem & Rock",
        "Grey",
        "Milano Black",
        "Raven Black",
        "Stellar Arctic",
        "Tan",
      ],
    },
  },

  {
    headerName: "Seat Belt",
    field: "seatBelt",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "N/A",
        "Lap",
        "3-point",
        "Retractable Lap",
        "Underseat Retractable",
      ],
    },
  },

  { headerName: "VIN#", field: "vin", sortable: true, filter: true },
  {
    headerName: "Body Number",
    field: "bodyNumber",
    sortable: true,
    filter: true,
  },

  // {
  //   headerName: "Arrival Status",
  //   field: "arrivalStatus",
  //   sortable: true,
  //   filter: true,
  //   cellEditor: "agSelectCellEditor",
  //   cellEditorParams: {
  //     values: ["In Stock", "Pending"],
  //   },
  // },

  {
    headerName: "Current Location",
    field: "currentLocation",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "HBS-Alvarado",
        "HBS-Burleson",
        "HBS-Cleburne",
        "HBS-Lewisville",
        "HBS-Florida",
        "Customer",
        "Factory",
        "Salesperson",
        "Victory Lot",
      ],
    },
  },

  {
    headerName: "Previous Owner",
    field: "previousOwner",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Pick-up City",
    field: "pickUpLocationCity",
    sortable: true,
    filter: true,
  },

  {
    headerName: "Pick-up State",
    field: "pickUpLocationState",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    },
  },

  {
    headerName: "Chassis Order Date",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "chassisOrderDate",
    valueFormatter: (params) => {
      if (!params.value) {
        return "";
      }
      const month = params.value.getMonth() + 1;
      const day = params.value.getDate();
      return `${params.value.getFullYear()}-${
        month < 10 ? "0" + month : month
      }-${day < 10 ? "0" + day : day}`;
    },
    cellEditor: "agDateCellEditor",

    // cellEditor: "dateEditor",
    // filter: "agDateColumnFilter",
    // filterParams: {
    //   clearButton: true,
    //   suppressAndOrCondition: true,
    //   comparator: function (filterLocalDateAtMidnight, cellValue) {
    //     var dateAsString = cellValue;
    //     var dateParts = dateAsString.split("/");
    //     var cellDate = new Date(
    //       Number(dateParts[2]),
    //       Number(dateParts[1]) - 1,
    //       Number(dateParts[0])
    //     );
    //     if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    //       return 0;
    //     }
    //     if (cellDate < filterLocalDateAtMidnight) {
    //       return -1;
    //     }
    //     if (cellDate > filterLocalDateAtMidnight) {
    //       return 1;
    //     }
    //   },
    // },
  },
  {
    headerName: "Chassis Targeted Completion",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "chassisTargetedCompletion",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    editable: true,
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Chassis Completion",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "chassisCompletion",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Chassis Invoice",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "chassisInvoice",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Chassis At Manufacturer",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "chassisAtManufacturer",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  // {
  //   headerName: "Chassis Aging",
  //   field: "chassisAging",
  //   sortable: true,
  //   filter: true,
  //   editable: false,
  //   valueGetter: (params) => {
  //     let d1 = new Date(),
  //       d2 = new Date(params.data.chassisAtManufacturer);
  //     return Math.round((d1 - d2).toString() / 1000 / 60 / 60 / 24);
  //   },
  //   cellStyle: function (params) {
  //     if (params.value < 90) {
  //       return { color: "blue" };
  //     } else if (params.value > 90 && params.value < 121) {
  //       return { color: "red" };
  //     } else {
  //       return { color: "red" };
  //     }
  //   },
  // },
  {
    headerName: "Body Order Date",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "bodyOrderDate",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    editable: true,
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Body Targeted Completion",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "bodyTargetedCompletion",
    cellEditor: "dateEditor",
    editable: true,
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Body Completion",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "bodyCompletion",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Body Invoice",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "bodyInvoice",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    editable: true,
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Days Owned",
    field: "daysOwned",
    editable: false,
    sortable: true,
    filter: true,
    valueGetter: (params) => {
      let d1 = new Date(),
        d2 = new Date(params.data.bodyInvoice),
        d3 = new Date(params.data.soldDate);

      if (params.data.inventoryType !== "Sold") {
        return Math.round((d1 - d2).toString() / 1000 / 60 / 60 / 24);
      } else {
        return Math.round((d1 - d3).toString() / 1000 / 60 / 60 / 24);
      }
    },
    cellStyle: function (params) {
      if (params.value < 90) {
        return { color: "blue" };
      } else if (params.value > 90 && params.value < 121) {
        return { color: "red" };
      } else {
        return { color: "red" };
      }
    },
  },
  // {
  //   headerName: "Floor Date",
  //   cellRenderer: (data) => {
  //     return data.value ? new Date(data.value).toLocaleDateString() : "";
  //   },
  //   field: "floorDate",
  //   cellEditor: "dateEditor",
  //   filter: "agDateColumnFilter",
  //   filterParams: {
  //     clearButton: true,
  //     suppressAndOrCondition: true,
  //     comparator: function (filterLocalDateAtMidnight, cellValue) {
  //       var dateAsString = cellValue;
  //       var dateParts = dateAsString.split("/");
  //       var cellDate = new Date(
  //         Number(dateParts[2]),
  //         Number(dateParts[1]) - 1,
  //         Number(dateParts[0])
  //       );
  //       if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
  //         return 0;
  //       }
  //       if (cellDate < filterLocalDateAtMidnight) {
  //         return -1;
  //       }
  //       if (cellDate > filterLocalDateAtMidnight) {
  //         return 1;
  //       }
  //     },
  //   },
  // },
  // {
  //   headerName: "Days Floored",
  //   field: "daysFloored",
  //   sortable: true,
  //   filter: true,
  //   valueGetter: (params) => {
  //     let d1 = new Date(),
  //       d2 = new Date(params.data.floorDate);
  //     return Math.round((d1 - d2).toString() / 1000 / 60 / 60 / 24);
  //   },
  //   cellStyle: function (params) {
  //     if (params.value < 90) {
  //       return { color: "blue" };
  //     } else if (params.value > 90 && params.value < 121) {
  //       return { color: "red" };
  //     } else {
  //       return { color: "red" };
  //     }
  //   },
  // },
  {
    headerName: "Chassis Cost",
    field: "newChassisCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Body Cost",
    field: "newBodyCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },
  // {
  //   headerName: "Used Bus Cost",
  //   field: "usedBusCost",
  //   sortable: true,
  //   filter: true,
  //   valueFormatter: currencyFormatter,
  // }, REMOVED REQIREMENT 9/18

  {
    headerName: "Total Bus Cost",
    field: "totalBusCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    valueGetter: (params) => {
      return params.data.newChassisCost + params.data.newBodyCost;
    },
  },
  {
    headerName: "Estimated Freight Cost",
    field: "freightCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Pdi",
    field: "pdi",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["350", "500", "750"],
    },
  },
  {
    headerName: "Interest Pac",
    field: "interestPac",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Admin PAc",
    field: "adminPac",
    sortable: true,
    filter: true,
    editable: false,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Good Will Pac",
    field: "goodWillPac",
    sortable: true,
    filter: true,
    editable: false,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Make Ready Projection",
    field: "makeReadyProjection",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    cellStyle: (params) =>
      params.data.makeReadyStatus === "Show Line"
        ? { color: "lightgray" }
        : { color: "black" },
  },

  {
    headerName: "Make Ready Cost",
    field: "makeReadyCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    cellStyle: (params) =>
      params.data.makeReadyStatus === "Show Line"
        ? { color: "blue" }
        : { color: "lightgray" },
  },

  {
    headerName: "Paint/Body Projection",
    field: "paintBodyProjection",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    cellStyle: (params) =>
      params.data.makeReadyStatus === "Show Line"
        ? { color: "lightgray" }
        : { color: "black" },
  },
  {
    headerName: "Paint/Body Cost",
    field: "paintBodyCost",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    cellStyle: (params) =>
      params.data.makeReadyStatus === "Show Line"
        ? { color: "blue" }
        : { color: "lightgray" },
  },

  {
    headerName: "Make Ready Status",
    field: "makeReadyStatus",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["Show Line", "In Process", "On Hold", "Unscheduled"],
    },
  },

  {
    headerName: "Check-in Date",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "checkInDate",
    editable: true,
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Show Line Date",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "showlineDate",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
  {
    headerName: "Worksheet Presentation Complete",
    field: "worksheetPresentationComplete",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Make Ready Standard",
    field: "makeReadyStandard",
    sortable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["Basic", "CPO", "New", "Standard"],
    },
  },
  {
    headerName: "Show Line Photos",
    field: "showlinePhotos",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Stock Folder Built",
    field: "stockFolderBuilt",
    sortable: true,
    filter: true,
  },
  {
    headerName: "Stock Folder Complete",
    field: "stockFolderComplete",
    sortable: true,
    filter: true,
  },

  // {
  //   headerName: "Total Prod Cogs",
  //   field: "totalProdCogs",
  //   sortable: true,
  //   filter: true,
  //   editable: false,
  //   valueFormatter: currencyFormatter,
  //   valueGetter: (params) => {
  //     let firstStepFinalProdCogs = 0;
  //     let secondStepFinalProdCogs = 0;
  //     let actualProdCogs = 0;
  //     let staticCosts =
  //       params.data.freightCost +
  //       params.data.pdi +
  //       params.data.interestPac +
  //       params.data.adminPac +
  //       params.data.goodWillPac;

  //     if (
  //       params.data.makeReadyStatus === "Show Line" ||
  //       params.data.makeReadyStatus === "Showline"
  //     ) {
  //       firstStepFinalProdCogs = params.data.makeReadyCost;
  //       secondStepFinalProdCogs = params.data.paintBodyCost;
  //     } else {
  //       firstStepFinalProdCogs = params.data.makeReadyProjection;
  //       secondStepFinalProdCogs = params.data.paintBodyProjection;
  //     }
  //     actualProdCogs =
  //       staticCosts + firstStepFinalProdCogs + secondStepFinalProdCogs;
  //     return actualProdCogs;
  //   },
  // },

  {
    headerName: "Total Cost",
    field: "totalCost",
    sortable: true,
    filter: true,
    editable: false,
    valueFormatter: currencyFormatter,
    valueGetter: (params) => {
      let firstStepFinalProdCogs = 0;
      let secondStepFinalProdCogs = 0;
      let actualProdCogs = 0;
      let staticCosts =
        params.data.freightCost +
        params.data.pdi +
        params.data.interestPac +
        params.data.adminPac +
        params.data.goodWillPac;

      if (
        params.data.makeReadyStatus === "Show Line" ||
        params.data.makeReadyStatus === "Showline"
      ) {
        firstStepFinalProdCogs = params.data.makeReadyCost;
        secondStepFinalProdCogs = params.data.paintBodyCost;
      } else {
        firstStepFinalProdCogs = params.data.makeReadyProjection;
        secondStepFinalProdCogs = params.data.paintBodyProjection;
      }
      actualProdCogs =
        staticCosts + firstStepFinalProdCogs + secondStepFinalProdCogs;
      // return params.getValue("totalProdCogs") + params.getValue("totalBusCost"); - original calculation for total cost
      return actualProdCogs + params.getValue("totalBusCost");
    },
  },
  {
    headerName: "Sales Price",
    field: "salesPrice",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },

  {
    headerName: "Gross Profit",
    field: "grossProfit",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    valueGetter: (params) => {
      return params.data.salesPrice - params.getValue("totalCost");
    },
    cellStyle: function (params) {
      if (params.value < 0) {
        //mark police cells as red
        return { color: "red" };
      } else if (params.value < 5999) {
        return { color: "black" };
      } else {
        return { color: "green" };
      }
    },
  },

  {
    headerName: "Rebates",
    field: "rebates",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Revised Gp",
    field: "revisedGp",
    sortable: true,
    filter: true,
    valueFormatter: currencyFormatter,
    valueGetter: (params) => {
      return params.getValue("grossProfit") + params.data.rebates;
    },
    cellStyle: function (params) {
      if (params.value < 0) {
        //mark police cells as red
        return { color: "red" };
      } else if (params.value < 5999) {
        return { color: "black" };
      } else {
        return { color: "green" };
      }
    },

    minWidth: 90,
    pinned: "right",
  },
  // {
  //   headerName: "GrossMargin",
  //   field: "grossMargin",
  //   // editable: true,
  //   sortable: true,
  //   filter: true,
  //   valueGetter: (params) => {
  //     return (
  //       (params.getValue("revisedGp") / params.getValue("salesPrice")) *
  //       100
  //     ).toFixed(2);
  //   },
  //   valueFormatter: percentageFormatter,
  // },
  { headerName: "Notes", field: "notes", sortable: true, filter: true },
  {
    headerName: "Sold Date",
    cellRenderer: (data) => {
      return data.value ? new Date(data.value).toLocaleDateString() : "";
    },
    field: "soldDate",
    cellEditor: "dateEditor",
    filter: "agDateColumnFilter",
    editable: true,
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
    },
  },
];

export const defaultColDef = {
  editable: true,
  resizable: true,
  // filter: true,
  // floatingFilter: true,
  // suppressKeyboardEvent: params => params.editing
};

/* CURRENCY FORMATTING FOR COST COLUMNS */

function currencyFormatter(params) {
  return "$" + formatNumber(params.value);
}

function formatNumber(number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

// Gross Margin Value Percentage Formatter
function percentageFormatter(params) {
  return formatNumber(params.value) + "%";
}

import * as Yup from "yup";
// import moment from 'moment';
import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    inventoryType,
    busType,
    busSize,
    busYear,
    busMake,
    busModel,
    amb,
    wc,
    rearStorage,
    overHeadStorage,
    bodyColor,
    chassisYear,
    chassisMake,
    chassisModel,
    axle,
    engine,
    fuelType,
    gvwr,
    mileage,
    seatMake,
    seatModel,
    seatSize,
    seatUph,
    seatUphColor,
    seatBelt,
    stock,
    vehicleCondition,
    vin,
    bodyNumber,
    // arrivalStatus,
    currentLocation,
    // previousOwner,
    pickUpLocationCity,
    pickUpLocationState,
    // chassisOrderDate,
    // chassisTargetedCompletion,
    // chassisCompletion,
    // chassisInvoice,
    // chassisAtManufacturer,
    bodyOrderDate,
    // bodyTargetedCompletion,
    // bodyCompletion,
    // bodyInvoice,
    // floorDate,
    pdi,
  },
} = checkoutFormModel;

const validationShapes = [
  // page 1
  Yup.object().shape({
    // bus body validations
    [inventoryType.name]: Yup.string().required(
      `${inventoryType.requiredErrorMsg}`
    ),
    [busType.name]: Yup.string().required(`${busType.requiredErrorMsg}`),
    [busSize.name]: Yup.string().required(`${busSize.requiredErrorMsg}`),
    [busYear.name]: Yup.string().required(`${busYear.requiredErrorMsg}`),
    [amb.name]: Yup.string().required(`${amb.requiredErrorMsg}`),
    [wc.name]: Yup.string().required(`${wc.requiredErrorMsg}`),
    // [rearStorage.name]: Yup.string().required(
    //   `${rearStorage.requiredErrorMsg}`
    // ),
    // [overHeadStorage.name]: Yup.string().required(
    //   `${overHeadStorage.requiredErrorMsg}`
    // ),
    [bodyColor.name]: Yup.string().required(`${bodyColor.requiredErrorMsg}`),
    [busMake.name]: Yup.string().required(`${busMake.requiredErrorMsg}`),
    [busModel.name]: Yup.string().required(`${busModel.requiredErrorMsg}`),
    [chassisYear.name]: Yup.string().required(
      `${chassisYear.requiredErrorMsg}`
    ),
    [chassisMake.name]: Yup.string().required(
      `${chassisMake.requiredErrorMsg}`
    ),
    [chassisModel.name]: Yup.string().required(
      `${chassisModel.requiredErrorMsg}`
    ),
    [axle.name]: Yup.string().required(`${axle.requiredErrorMsg}`),
    // [engine.name]: Yup.string().required(`${engine.requiredErrorMsg}`),
    // [fuelType.name]: Yup.string().required(`${fuelType.requiredErrorMsg}`),
    // [gvwr.name]: Yup.string().required(`${gvwr.requiredErrorMsg}`),
    // [mileage.name]: Yup.string().required(`${mileage.requiredErrorMsg}`),
    // [seatMake.name]: Yup.string().required(`${seatMake.requiredErrorMsg}`),
    // [seatModel.name]: Yup.string().required(`${seatModel.requiredErrorMsg}`),
    // [seatSize.name]: Yup.string().required(`${seatSize.requiredErrorMsg}`),
    // [seatUph.name]: Yup.string().required(`${seatUph.requiredErrorMsg}`),
    // [seatUphColor.name]: Yup.string().required(
    //   `${seatUphColor.requiredErrorMsg}`
    // ),
    // [seatBelt.name]: Yup.string().required(`${seatBelt.requiredErrorMsg}`),
  }),
  // page 2
  // Additional Info validations
  Yup.object().shape({
    [vin.name]: Yup.string()
      .required(`${vin.requiredErrorMsg}`)
      .test("len", `${vin.invalidErrorMsg}`, (val) => val && val.length === 17),
    [stock.name]: Yup.string().required(`${stock.requiredErrorMsg}`),
    [vehicleCondition.name]: Yup.string().required(
      `${vehicleCondition.requiredErrorMsg}`
    ),
    [bodyNumber.name]: Yup.string().required(`${bodyNumber.requiredErrorMsg}`),
    // [arrivalStatus.name]: Yup.string().required(
    //   `${arrivalStatus.requiredErrorMsg}`
    // ), REMOVED REQUIREMENT 11/21
    [currentLocation.name]: Yup.string().required(
      `${currentLocation.requiredErrorMsg}`
    ),
    // [previousOwner.name]: Yup.string().required(
    //   `${previousOwner.requiredErrorMsg}`
    // ), REMOVED REQUIREMENT 11/21
    // [pickUpLocationCity.name]: Yup.string().required(
    //   `${pickUpLocationCity.requiredErrorMsg}`
    // ),
    // [pickUpLocationState.name]: Yup.string().required(
    //   `${pickUpLocationState.requiredErrorMsg}`
    // ),
    // [chassisOrderDate.name]: Yup.string().required(`${chassisOrderDate.requiredErrorMsg}`), REMOVED REQIREMENT 9/18
    // [chassisTargetedCompletion.name]: Yup.string().required(
    //   `${chassisTargetedCompletion.requiredErrorMsg}`
    // ),
    // [chassisCompletion.name]: Yup.string().required(
    //   `${chassisCompletion.requiredErrorMsg}`
    // ), REMOVED REQUIREMENT 9/18
    // [chassisInvoice.name]: Yup.string().required(
    //   `${chassisInvoice.requiredErrorMsg}`
    // ),
    // [chassisAtManufacturer.name]: Yup.string().required(
    //   `${chassisAtManufacturer.requiredErrorMsg}`
    // ),REMOVED REQUIREMENT 11/21
    // [bodyOrderDate.name]: Yup.string().required(
    //   `${bodyOrderDate.requiredErrorMsg}`
    // ),
    // [bodyTargetedCompletion.name]: Yup.string().required(
    //   `${bodyTargetedCompletion.requiredErrorMsg}`
    // ),
    // [bodyCompletion.name]: Yup.string().required(
    //   `${bodyCompletion.requiredErrorMsg}`
    // ),
    // [bodyInvoice.name]: Yup.string().required(
    //   `${bodyInvoice.requiredErrorMsg}`
    // ),
    // [floorDate.name]: Yup.string().required(`${floorDate.requiredErrorMsg}`), REMOVED REQUIREMENT 11/21
  }),
  // page 3
  // Cost of Goods validation
  // Yup.object().shape({
  //   [pdi.name]: Yup.string().required(`${pdi.requiredErrorMsg}`),
  //   // .test('len', `${pdi.invalidErrorMsg}`, val => val && val.length === 3),
  // }),
];

export default validationShapes;

import {
  Box,
  Button,
  IconButton,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../Header";
import { mockTransactions } from "../../../data/mockData";
import StatBox from "../../../Components/StatBox";
import ProgressCircle from "../../ProgressCircle";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import TrafficIcon from "@mui/icons-material/Traffic";
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined";
import { BarChart } from "../../../Components/BarChart";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import Team from "../team/";

import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";

// import Pie from "./pie";

const TopDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Row Data: The data to be displayed.
  const [buses, setBuses] = useState([]);
  useEffect(() => {
    axios
      .get("https://mern-heroku-netlify.herokuapp.com/api/buses/")
      .then((res) => {
        // console.log(res.data);
        setBuses(res.data);
      });
  }, []);

  // COUNT OF STOCK BUSES
  const stockBuses = buses.reduce((counter, obj) => {
    if (
      obj.inventoryType === "Arriving Soon" ||
      obj.inventoryType === "Stock" ||
      obj.inventoryType === "Stock On Order"
    )
      counter += 1;
    return counter;
  }, 0);

  // COUNT OF SHOWLINE BUSES
  const showLineBuses = buses.reduce((counter, obj) => {
    if (obj.makeReadyStatus === "Show Line") counter += 1;
    return counter;
  }, 0);

  // AVERAGE RETAIL PRICE PER BUS
  const averageRetailPrice = buses.reduce((counter, obj) => {
    function limit(string = "", limit = 9) {
      return string.substring(0, limit);
    }

    const options = {
      style: "decimal", // Other options: 'currency', 'percent', etc.
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
    };
    const number = (counter += 1);

    counter += obj.salesPrice;
    // if (obj.inventoryType.includes("Stock")) console.log(obj.inventoryType);
    console.log(obj.salesPrice);
    const average = counter / 140;
    const formattedNumberDefault = limit(
      counter.toLocaleString("en-US", options)
    );

    return formattedNumberDefault;

    // busAverage = price / counter;
  }, 0);

  // COUNT OF IN-MAKE READY BUSES
  const makeReadyBuses = buses.reduce((counter, obj) => {
    if (obj.makeReadyStatus === "In Process") counter += 1;
    return counter;
  }, 0);

  // COUNT OF 180 DAY BUSES
  const oneEightyDays = buses.reduce((counter, obj) => {
    if (obj.daysOwned > 180) counter += 1;
    return counter;
  }, 0);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
      </Box>
      <Box>
        {/*         <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <DownloadOutlinedIcon sx={{ mr: "10px" }} />
          Download Reports
        </Button> */}
      </Box>
      {/* GRID SYSTEM & CHARTS*/}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        marginTop="8px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={stockBuses}
            subtitle="Buses In-Stock or Arriving Soon"
            progress="0.75"
            increase="+14%"
            icon={
              <AirportShuttleOutlinedIcon
                sx={{
                  color: colors.greenAccent[600],
                  fontSize: "26px",
                }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={showLineBuses}
            subtitle="Buses Show Line Ready"
            progress="0.50"
            increase="+21%"
            icon={
              <ThumbUpAltOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={makeReadyBuses}
            subtitle="Buses In Make Ready"
            progress="0.30"
            increase="+5%"
            icon={
              <BuildOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title=" "
            subtitle="Avg. Days To Show Line"
            progress="0.40"
            increase="-27%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={oneEightyDays}
            subtitle="Buses 180 Days In-Stock"
            progress="0.43"
            increase="+17%"
            icon={
              <AccessAlarmOutlinedIcon
                sx={{
                  color: colors.greenAccent[600],
                  fontSize: "26px",
                }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="58"
            subtitle="Buses 360 Days In-Stock"
            progress="0.50"
            increase="+21%"
            icon={
              <ErrorOutlineOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="245.5"
            subtitle="Average Days In-Stock"
            progress="0.30"
            increase="+5%"
            icon={
              <HourglassEmptyOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={averageRetailPrice}
            subtitle="Avg. Unit Retail Price"
            progress="0.80"
            increase="+43%"
            icon={
              <FunctionsOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 12"
          gridRow="span 5"
          backgroundColor={colors.primary[400]}
          p="40px"
        >
          <Typography variant="h5" fontWeight="600"></Typography>
          <Box display="flex" flexDirection="row" alignItems="center" mt="25px">
            <Team />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopDashboard;
